<template>
    <modal-dialog
        id="bulk-assign-dialog"
        :show.sync="shown"
    >
        <template v-slot:title>
            <div class="modal-title h5">{{ $root.translate("Assign {name}", { name: activity.name }) }}</div>
            <div class="modal-title h5 text-gray">{{ subject.name }}</div>
        </template>

        <form-dropdown
            v-if="raw_groups.length > 1"
            id="group_id"
            name="group_id"
            :value="state.filter"
            :options="groups"
            :required="true"
            :editable="true"
            class="mb-4"
            @input="set_group_id($event)"
        />

        <data-table
            id="bulk-assign-students-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :show-head="false"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :clickable="false"
            :selection="selected_row_ids"
            @select="select_row"
            @filter-by-selection="filter_by_selection"
            @clear-selection="clear_selection"
            @search="search"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6 class="d-flex">
                    <avatar :student="row" class="mr-2" />
                    <span>{{ row.username }}</span>
                </h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'username'"
                    class="d-flex"
                >
                    <avatar :student="row" class="mr-2" />
                    <span>{{ row.username }}</span>
                </div>
                <div
                    v-else-if="field === 'grade'"
                >
                    <form-dropdown
                        v-if="selected_row_ids.indexOf(row.id) >= 0"
                        :id="`grade-${row.id}`"
                        name="grade"
                        :value="row._subject_grade"
                        :options="available_grades_for_row(row)"
                        :empty-value="-1"
                        :required="true"
                        @input="set_grade_for_row_id(row.id, $event.id)"
                    />
                    <span v-else class="text-gray">{{ grade_by_id(row._subject_grade).name }}</span>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    class="mr-2"
                    :disabled="saving"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    class="ml-2"
                    :disabled="!selected_row_ids.length"
                    :waiting="saving"
                    @click.prevent="save"
                >
                    <span v-if="!selected_row_ids.length">{{ $root.translate("Select Student(s) in the list") }}</span>
                    <span v-else>{{ $root.translate("Assign to 1 student:::Assign to {number} students", { number: selected_row_ids.length }, selected_row_ids.length) }}</span>
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import handles_grades from "@/custom/mixins/HandlesGrades"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import Avatar from "@/custom/components/Avatar"

export default {
    name: "BulkAssignActivityDialog",
    mixins: [is_remote_data_table_source, handles_grades],
    components: {
        FormDropdown,
        DefaultButton,
        ModalDialog,
        Avatar
    },
    mounted () {
        this.maybe_load_groups()
    },
    watch: {
        profile_id: "maybe_load_groups",
        shown: "maybe_load_groups"
    },
    methods: {
        maybe_load_groups () {
            if(this.profile_id && this.shown) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "group",
                        query: {
                            fields: ["fieldset::manager"]
                        }
                    }
                ).then(() => {
                    const groups = this.raw_groups
                    if(groups.length) {
                        const group = groups.find(group => group.id === this.state.filter) || groups[0]
                        if(group) this.set_group_id(group.id)
                    }
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.$emit("waiting", false)
                })
            }
        },
        set_group_id (group_id) {
            this.filter("group_id", group_id)
            this.refresh()
        },
        pre_load () {
            this.set_state_value("subject_tag_id", this.activity ? this.activity.subject_tag_id : 0)
        },
        select_row (identifier, select) {
            const index = this.selected_row_ids.indexOf(identifier)
            if(select && (index < 0)) {
                this.selected_row_ids.push(identifier)
                const student = this.raw_group_users.find(group_user => group_user.id === identifier) || { _subject_grade: 0 }
                this.grades.push(student._subject_grade)
            } else if(!select && (index >= 0)) {
                this.selected_row_ids.splice(index, 1)
                this.grades.splice(index, 1)
            }
        },
        clear_selection () {
            this.selected_row_ids = []
            this.grades = []
        },
        set_grade_for_row_id (identifier, grade) {
            const index = this.selected_row_ids.indexOf(identifier)
            if(index >= 0) this.grades[index] = grade
        },
        save () {
            this.saving = true
            const data = {
                group_user_ids: this.selected_row_ids,
                grades: this.grades
            }
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "asset",
                    id: this.activity.id,
                    action: "assign",
                    data,
                    method: "post",
                    passthru: true
                }
            ).then(() => {
                this.$success(this.$root.translate("Activity assigned."))
                this.shown = false
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.saving = false
            })
        },
        available_grades_for_row (row) {
            if(this.activity) {
                return this.raw_grades.filter(grade => {
                    return (grade.id === row._subject_grade) || !this.activity.grades || (this.activity.grades.indexOf(grade.id) >= 0)
                })
            }
            return this.available_grades
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        shown: {
            get () {
                return !!this.show && !!this.activity && !!this.activity.id
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        state_identifier () {
            return "bulk-assign-dialog"
        },
        fields () {
            return ["fieldset::bulk-assign"]
        },
        can_refresh () {
            return this.profile_id && this.shown
        },
        raw_subjects () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_SUBJECT").id
            return this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy)
        },
        raw_groups () {
            return this.entity_records("group")
        },
        raw_group_users () {
            if(!this.state.filter) return []
            const role = this.constants("group_user_types", "ROLE_STUDENT").id
            return this.entity_records("group_user").filter(group_user => {
                return (group_user.role === role) && (group_user.group_id === this.state.filter)
            })
        },
        groups () {
            return orderBy(this.raw_groups, "name", "asc")
        },
        group () {
            if(!this.state.filter) return {}
            return this.raw_groups.find(group => group.id === this.state.filter) || {}
        },
        subject () {
            if(!this.activity || !this.activity.id) return ""
            return this.raw_subjects.find(subject => subject.id === this.activity.subject_tag_id) || {}
        }
    },
    props: {
        activity: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "group_user",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "username",
                sort_dir: "asc",
                filter_on: "group_id",
                filter: 0,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                subject_tag_id: 0
            },
            columns: {
                username: { label: this.translate("Student") },
                grade: { label: this.translate("Grade") }
            },
            selected_row_ids: [],
            grades: [],
            saving: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#bulk-assign-dialog {
    table.table {
        .avatar {
            background-color: $bg-color;
        }
        td {
            h6.d-flex, div.d-flex {
                align-items: center;
            }
        }
    }
}
</style>
