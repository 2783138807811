<template>
    <div class="column col-3 col-md-4 col-sm-12 mb-4 vocabulary-term">
        <div class="card">
            <div class="card-body">
                <div v-if="editable && !!record && !record.id && !create_new_record" class="empty">
                    <div class="empty-action">
                        <default-button
                            color="primary"
                            size="lg"
                            class="s-circle"
                            @click.prevent="create_new_record = true"
                        >
                            <open-icon glyph="plus" />
                        </default-button>
                    </div>
                </div>
                <div v-else class="columns">
                    <div class="column col-3">
                        <div v-if="!!record" class="nibnut-aspect-ratio-box nibnut-ratio-square">
                            <div>
                                <image-upload-input
                                    v-if="editable"
                                    id="image_url"
                                    name="image_url"
                                    glyph="image"
                                    :url="record.image_url || held_images.image_url || ''"
                                    :alt="record.name || ''"
                                    :accepts="upload_accepts('image_url')"
                                    :uploading="!!uploading.image_url"
                                    :uploaded="uploaded.image_url||0"
                                    :deletable="false"
                                    :editable="!creating"
                                    @upload="upload_file_list('image_url', $event)"
                                    @clear="confirm_clear_file('image_url')"
                                />
                                <img
                                    v-else
                                    :src="record.image_url"
                                    :alt="record.name"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="column col-6">
                        <form-input
                            v-if="editable"
                            id="name"
                            name="name"
                            v-model="record.name"
                            :required="true"
                            :editable="!creating"
                            :creating="saving('name')"
                            :error="has_error('name')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Term") }}</template>
                        </form-input>
                        <div v-else class="text-center">
                            {{ record.name }}
                        </div>
                    </div>
                    <div class="column col-3 text-center">
                        <div class="nibnut-aspect-ratio-box nibnut-ratio-square">
                            <div>
                                <upload-input
                                    v-if="editable"
                                    id="sound_url"
                                    name="sound_url"
                                    glyph="volume-up"
                                    :url="record.sound_url ? record.sound_url : (held.sound_url ? 'HELD.mp3' : '')"
                                    :alt="record.name"
                                    :accepts="upload_accepts('sound_url')"
                                    :uploading="!!uploading.sound_url"
                                    :uploaded="uploaded.sound_url||0"
                                    :editable="!creating"
                                    :deletable="false"
                                    @upload="upload_file_list('sound_url', $event)"
                                    @clear="confirm_clear_file('sound_url')"
                                >
                                    <template v-slot:display>
                                        <div
                                            :class="{ 'bg-secondary': (!!held.sound_url || !!record.sound_name) }"
                                            class="empty"
                                        >
                                            <div class="empty-icon text-center">
                                                <open-icon
                                                    glyph="volume-up"
                                                    size="2x"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </upload-input>
                                <default-button
                                    v-else
                                    flavor="link"
                                    :title="button_title"
                                    size="lg"
                                    @click.prevent="pause_or_play"
                                >
                                    <open-icon
                                        :glyph="button_glyph"
                                        size="3x"
                                    />
                                </default-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!!record && editable && (!!record.id || (!record.id && create_new_record))"
                class="text-center card-footer"
            >
                <default-button
                    v-if="!!record && !!record.id && !creating"
                    flavor="link"
                    color="error"
                    size="sm"
                    @click.prevent="confirm_record_delete"
                >
                    {{ $root.translate("Delete Term") }}
                </default-button>
                <default-button
                    v-if="!!record && (!record.id || creating) && create_new_record"
                    flavor="link"
                    size="sm"
                    :disabled="creating"
                    class="mx-2"
                    @click.prevent="reset"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    v-if="!!record && (!record.id || creating) && create_new_record"
                    color="primary"
                    size="sm"
                    :waiting="creating"
                    :disabled="creating"
                    class="mx-2"
                    @click.prevent="create"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </div>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import handles_uploads from "@/nibnut/mixins/HandlesUploads"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import ImageUploadInput from "@/nibnut/components/Inputs/ImageUploadInput"
import UploadInput from "@/nibnut/components/Inputs/UploadInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "VocabularyTerm",
    mixins: [handles_saving, handles_uploads],
    components: {
        FormInput,
        ImageUploadInput,
        UploadInput,
        DefaultButton,
        OpenIcon
    },
    watch: {
        "held.image_url": "regenerate_held_image"
    },
    methods: {
        create () {
            this.creating = true
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "glossary_term",
                    data: { ...this.record }
                }
            ).then(record => {
                const completed = () => {
                    this.reset()
                    this.$emit("saved")
                }
                const upload_sound = () => {
                    if(this.held.sound_url) {
                        this.upload_file("sound_url", this.held.sound_url).then(() => {
                            completed()
                        }).catch(error => {
                            this.$error(error.message)
                        })
                    } else completed()
                }
                this.record.id = record.id
                if(this.held.image_url) {
                    this.upload_file("image_url", this.held.image_url).then(() => {
                        upload_sound()
                    }).catch(error => {
                        this.$error(error.message)
                    })
                } else upload_sound()
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) return this.save_field_for_record_id("glossary_term", this.record.id, this.record[field], field)
            }
            return Promise.resolve()
        },
        upload_accepts (name, filename = null) {
            if(name === "sound_url") {
                if(filename) return !!filename.match(/\.mp3$/i)
                return "audio/mpeg,audio/mpeg3,audio/x-mpeg-3,audio/mp3,.mp3"
            }
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: "glossary_term",
                id: this.record.id
            }
        },
        upload_attach_error (name, error) {
            this.$error(error.message)
        },
        upload_detach_error (name, error) {
            this.$error(error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        regenerate_held_image () {
            this.generate_held_image("image_url")
        },
        reset () {
            this.create_new_record = false
            this.creating = false
            this.record.id = 0
            this.record.name = ""
            this.record.image_url = ""
            this.record.sound_url = ""
            this.record.sound_name = ""
            this.$set(this.held, "image_url", null)
            this.$set(this.held, "sound_url", null)
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.record.name }),
                    message: this.$root.translate("Do you really want to delete this term? There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "glossary_term",
                        id: this.record.id
                    }
                ).then(record => {
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        pause_or_play () {
            document.activeElement.blur()
            if(this.playing) this.$emit("pause", this.record)
            else this.$emit("play", this.record)
        }
    },
    computed: {
        holding_mode () {
            return !this.record || !this.record.id
        },
        button_title () {
            if(this.playing) return this.$root.translate("Stop")
            return this.$root.translate("Play")
        },
        button_glyph () {
            if(this.playing) return "stop-circle"
            return "play-circle"
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        playing: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            create_new_record: false,
            creating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.vocabulary-term {
    .card {
        & > .card-body,
        & > .card-footer {
            padding: $control-padding-y-sm;
        }
        & > .card-body {
            & > .columns {
                align-items: center;
            }
            & > .empty {
                padding: 1.7rem 0.8rem;

                .empty-action {
                    margin-top: 0;
                }
            }
            .nibnut-aspect-ratio-box {
                .empty {
                    position: relative;
                    padding: 0;

                    .empty-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .empty-title, .empty-action { display: none; }
                }
            }
            .btn.btn-lg {
                height: 100%;

                &,
                &:focus,
                &:active,
                &.active {
                    border-color: transparent;
                }
                @include hover-supported {
                    border-color: transparent;
                }
            }
        }
    }
}
.nibnut.student-content {
    .vocabulary-term {
        .card {
            & > .card-body {
                .lar, .las, .lab {
                    font-size: 3em;
                }
            }
        }
    }
}
</style>
