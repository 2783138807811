<template>
    <div
        v-if="!!game"
        :class="{ [`grid-tile-${size}`]: true, empty: !!game && !game.image_url, active: selected, disabled }"
        class="game-tile grid-tile"
    >
        <img
            :src="image_url"
            :alt="game.name"
        />
        <div v-if="hoverable">
            <div class="p-6">
                <h6 class="text-center">{{ game.name }}</h6>
                <div v-html="game.description" class="text-justify"></div>
            </div>
        </div>
    </div>
</template>

<script>
import default_game_image from "@/assets/img/logo.png"

export default {
    name: "GameTile",
    computed: {
        image_url () {
            if(!!this.game && !!this.game.image_url) return this.game.image_url
            return default_game_image
        }
    },
    props: {
        game: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md)$/i),
            default: "md"
        },
        selected: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hoverable: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut.student-content {
    .game-tile {
        font-size: 14px;
    }
}
</style>
