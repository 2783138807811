<template>
    <div
        class="slider-tabs"
    >
        <div
            :style="{ left, width }"
            class="bg"
        ></div>
        <div
            v-for="tab in tabs"
            :key="tab.id"
            :class="{ [`slider-${tab.id}`]: true, active: (tab.id === currentTabId) }"
            class="slider clickable"
            @click.prevent="slide(tab)"
        >
            <slot name="slider" :tab="tab">
                <base-button
                    flavor="link"
                    class="hover-disabled"
                    @click.prevent.stop="slide(tab)"
                >
                    <img
                        v-if="!!tab.img"
                        :src="tab.img"
                    />
                    <flat-icon
                        v-else-if="!!tab.glyph && (tab.library === 'flat')"
                        :glyph="tab.glyph"
                    />
                    <open-icon
                        v-else-if="!!tab.glyph"
                        :glyph="tab.glyph"
                        :size="tab.glyph_size || ''"
                    />
                    <span v-if="!!tab.label">{{ tab.label }}</span>
                </base-button>
            </slot>
        </div>
    </div>
</template>

<script>
import BaseButton from "@/nibnut/components/Buttons/BaseButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import FlatIcon from "@/custom/components/FlatIcon"

export default {
    name: "SliderTabs",
    components: {
        BaseButton,
        OpenIcon,
        FlatIcon
    },
    methods: {
        slide (tab) {
            document.activeElement.blur()
            this.$emit("click", tab.id)
        }
    },
    computed: {
        left () {
            const index = this.tabs.findIndex(tab => tab.id === this.currentTabId)
            if(index >= 0) return `${(index / this.tabs.length) * 100}%`
            return 0
        },
        width () {
            if(this.tabs.length) return `${(1 / this.tabs.length) * 100}%`
            return 0
        }
    },
    props: {
        tabs: {
            type: Array,
            default () {
                return [] // { id: "", label: "", img: "", glyph: "", library: "" }
            }
        },
        currentTabId: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.slider-tabs {
    position: relative;
    display: flex;
    width: 100%;
    z-index: 0;

    & > div.slider,
    & > .bg {
        padding: $control-padding-y 0;
    }

    & > div.slider {
        position: relative;
        display: block;
        flex: 1 1 50%;
        width: 50%;
        height: 100%;
        text-align: center;
        text-decoration: none;
        border-top: 1px solid $brand-green;
        border-bottom: 1px solid $brand-green;
        z-index: $zindex-2;
        transition: 0.5s;

        .btn.btn-link {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            transition: 0.5s;

            img {
                max-height: 1.7em;
            }
            i.fi, i.las, i.lab {
                font-size: 1.7em;
                transform: rotate(0deg);
                transition: transform 0.5s;
            }
            span {
                flex: 1 0 100%;
            }
        }

        &.active {
            .btn.btn-link {
                color: $light-color;

                i.fi, i.las, i.lab {
                    transform: rotate(90deg);
                }
            }
        }
    }
    & > .bg {
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        left: 0;
        background-color: $brand-green;
        color: $light-color;
        z-index: $zindex-1;
        transition: 0.2s;
    }
}
</style>
