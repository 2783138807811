<template>
    <div id="subscription-pricing-table">
        <h4 v-if="autoApplied && !!coupon">{{ coupon.name }}</h4>
        <h5>
            <span v-if="!!currentUnitPrice">{{ stripe_plan_price(plan, current_unit_price, translate("student:::students", {}, 1)) }}</span>
            <span v-else>{{ unit_price }}</span>
            <small v-if="(current_unit_price > 0) || (unit_price > 0)" class="text-gray">&nbsp;+&nbsp;{{ translate("tx") }}</small>
        </h5>
        <div v-if="!!coupon" class="text-strikethrough text-gray">
            {{ regular_unit_price }}<small class="text-gray">&nbsp;+&nbsp;{{ translate("tx") }}</small>
        </div>
        <div v-if="!!coupon" class="text-small mt-2">
            {{ coupon_description(coupon, translate("student:::students", {}, 1), plan.interval, plan.interval_count, description_components) }}
        </div>
        <div v-if="(hardMaximum > 0) && (hardMaximum > qty)" class="mt-4">
            {{ translate("You can add back up to {count} students without any immediate charge.", { count: (hardMaximum - qty) }) }}<br />
            {{ translate("The new student total will be the one charged at renewal.") }}
        </div>
        <table class="table table-striped mt-4">
            <tbody>
                <tr>
                    <td class="col-label">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow">
                                {{ translate("Number of students") }}
                            </div>
                            <div class="flex-static">
                                <form-input
                                    id="qty"
                                    name="qty"
                                    type="number"
                                    :value="qty"
                                    :min="minSeats"
                                    :required="true"
                                    :live="true"
                                    class="ml-2"
                                    @input="save"
                                />
                            </div>
                        </div>
                    </td>
                    <td class="col-total">
                        <div class="d-flex align-items-center">
                            <div class="flex-static">
                                <help-button
                                    class="popover-bottom"
                                >
                                    <p>{{ translate("1 student minimum:::{n} students minimum", { n: minSeats }, minSeats) }}</p>
                                </help-button>
                            </div>
                            <div class="flex-grow">
                                {{ stripe_amount(total_yearly, plan.interval, plan.interval_count, "") }}
                                <small v-if="!!total_yearly && (!taxes || !taxes.length)" class="d-invisible">&nbsp;+&nbsp;{{ translate("tx") }}</small>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr
                    v-for="tax in taxes"
                    :key="tax.id"
                >
                    <td class="col-label">
                        {{ tax.name }}
                    </td>
                    <td class="col-total">
                        <div class="d-flex align-items-center">
                            <div class="flex-static"><open-icon glyph="plus" /></div>
                            <div class="flex-grow">
                                {{ stripe_amount(tax_amount(tax), plan.interval, plan.interval_count, "") }}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="col-label">
                        <span v-if="fee_change < 0">{{ translate("Amount at Renewal") }}</span>
                        <span v-else>{{ translate("Total") }}</span>
                    </td>
                    <td class="col-total">
                        <div class="d-flex align-items-center">
                            <div class="flex-static"><open-icon glyph="equals" /></div>
                            <div class="flex-grow">
                                {{ stripe_amount(taxed_total_yearly, plan.interval, plan.interval_count, "") }}
                                <small v-if="!!taxed_total_yearly && (!taxes || !taxes.length)" class="text-gray">&nbsp;+&nbsp;{{ translate("tx") }}</small>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr
                    v-if="fee_change > 0"
                    class="text-error"
                >
                    <td class="col-label">
                        {{ translate("Owed") }}
                    </td>
                    <td class="col-total">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow">
                                {{ (absolute_fee_change / 100) | nibnut.currency }}
                                <small v-if="!taxes || !taxes.length" class="d-invisible">&nbsp;+&nbsp;{{ translate("tx") }}</small>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import handles_subscription from "@/custom/mixins/HandlesSubscription"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import HelpButton from "@/nibnut/components/Buttons/HelpButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "SubscriptionPricingTable",
    mixins: [handles_subscription, misc_utilities],
    components: {
        FormInput,
        HelpButton,
        OpenIcon
    },
    mounted () {
        this.reset_qty()
        this.reset_billing_location()
        this.coupon_code = this.appliedCouponCode
    },
    watch: {
        minSeats: "reset_qty",
        softMaximum: "reset_qty",
        billingCountry: "reset_billing_location",
        billingProvince: "reset_billing_location",
        currentUnitPrice: "emit_fee_change",
        "paymentInfo.last_payment.total": "emit_fee_change",
        appliedCouponCode: function () {
            this.coupon_code = this.appliedCouponCode
        }
    },
    methods: {
        reset_qty () {
            this.qty = this.softMaximum
            if(this.qty < this.minSeats) this.save(this.minSeats, "qty")
        },
        reset_billing_location () {
            this.country = this.billingCountry
            this.province = this.billingProvince
            this.emit_fee_change()
        },
        tax_amount (tax_rate) {
            return this.tax_amount_for_price(this.total_yearly, tax_rate)
        },
        save (value, field) {
            this[field] = value
            if(field === "qty") value = this.quantity
            this.$emit("input", this[field], field)
            this.emit_fee_change()
        },
        emit_fee_change () {
            this.$emit("change", this.fee_change)
        }
    },
    computed: {
        description_components () {
            const components = []
            if(!this.autoApplied) components.push("discount")
            components.push("duration")
            return components
        },
        quantity () {
            return Math.max(this.minSeats, this.qty)
        },
        current_unit_price () {
            return this.currentUnitPrice || this.plan_price(this.plan, this.coupon, [])
        },
        total_yearly () {
            let current_unit_price = this.current_unit_price
            if(!current_unit_price && !this.coupon) current_unit_price = this.plan.amount
            return (this.quantity * current_unit_price) || 0
        },
        taxed_total_yearly () {
            return this.add_taxes_to_price(this.taxes, this.total_yearly)
        },
        next_total () {
            return (this.quantity * (this.paymentInfo.next_payment.amount || this.current_unit_price || this.plan.amount)) || 0
        },
        next_taxed_total () {
            return this.add_taxes_to_price(this.taxes, this.next_total)
        },
        fee_change () {
            if(!this.paymentInfo.last_payment.total) return 0
            return this.taxed_total_yearly - this.paymentInfo.last_payment.total
        },
        absolute_fee_change () {
            return Math.abs(this.fee_change)
        },
        taxes () {
            if(!this.country || !this.province || !this.total_yearly) return []

            return this.entity_records("tax_rate").filter(tax_rate => {
                return (tax_rate.jurisdiction === this.country) && (tax_rate.provinces.indexOf(this.province) >= 0)
            })
        },
        applied_taxes () {
            const taxes = this.taxes
            if(!!taxes && !!taxes.length) {
                return taxes.map(tax => tax.name).join(", ")
            }
            return this.translate("tx")
        }
    },
    props: {
        minSeats: {
            type: Number,
            validator: prop => prop > 0,
            required: true
        },
        paymentInfo: {
            type: Object,
            default () {
                return {
                    last_payment: {},
                    next_payment: {}
                }
            }
        },
        appliedCouponCode: {
            type: String,
            default: ""
        },
        autoApplied: {
            type: Boolean,
            default: false
        },
        billingCountry: {
            type: String,
            default: "CA"
        },
        billingProvince: {
            type: String,
            default: ""
        },
        currentUnitPrice: {
            type: Number,
            default: 0
        },
        softMaximum: {
            type: Number,
            default: 0
        },
        hardMaximum: {
            type: Number,
            default: 0
        },
        invitationToken: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#subscription-pricing-table {
    input.form-input {
        max-width: 100px;
    }
    & > :not(table) {
        text-align: center;
    }
    & > table {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        td.col-label {
            width: 100%;
            text-align: right;
        }
        td.col-total {
            white-space: nowrap;
            text-align: right;

            .popover-container {
                text-align: left;
            }

            .flex-static {
                button.btn, & > i.las {
                    float: left;
                    font-weight: bold;
                    margin-right: $control-padding-x;
                }
                button.btn {
                    padding: 0;
                    font-size: 1rem;
                    height: auto;
                    margin: 0 0 0 -0.3rem;
                    i.las {
                        font-size: 1.4rem;
                    }
                }
                & > i.las {
                    border-radius: 50%;
                    border: $border-width solid $primary-color;
                    padding: $control-padding-y-sm $control-padding-y-sm;
                }
            }
        }
        tfoot {
            font-weight: bold;
            td.col-label {
                text-transform: uppercase;
            }
        }
    }
}
</style>
