<template>
    <div
        v-if="size.match(/^(xs|sm)$/)"
        :class="{ [size]: true, [color]: true }"
        class="ball-pulse-sync loader"
    >
        <div
            v-for="index in 3"
            :key="index"
        ></div>
    </div>
    <div
        v-else
        :class="{ [size]: true, [color]: true }"
        class="loader"
    >
        <img src="@/assets/img/flying-dragon.gif" />
    </div>
</template>

<script type="text/javascript">
export default {
    name: "Loader",
    props: {
        size: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(xs|sm|md|lg)$/),
            default: "lg"
        },
        color: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(gray|light|primary)$/),
            default: "primary"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";
@import "~loaders.css/src/animations/ball-pulse-sync";

$ball-size: 15px;
.loader {
    width: auto;
    white-space: nowrap;
    text-align: center;

    & > div {
        width: $ball-size;
        height: $ball-size;
        margin: $unit-2;
    }

    &.xs {
        & > div {
            width: $ball-size / 4;
            height: $ball-size / 4;
            margin: $unit-1 / 2;
        }
    }
    &.sm {
        & > div {
            width: $ball-size / 2;
            height: $ball-size / 2;
            margin: $unit-1;
        }
    }
    &.md {
        & > img {
            height: 75px;
        }
    }
    &.lg {
        & > div {
            width: $ball-size * 2;
            height: $ball-size * 2;
            margin: $unit-4;
        }
    }

    &.light {
        & > div {
            background-color: $light-color;
        }
    }
    &.gray {
        & > div {
            background-color: $gray-color;
        }
    }
}
@keyframes ball-pulse-sync {
  33% {
    transform: translateY(50%);
  }
  66% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
