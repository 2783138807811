<template>
    <div
        class="visual-password-input"
    >
        <div v-if="disabled" class="columns visual-password-token-containers read-only">
            <div
                v-for="index in 3"
                :key="index"
                class="column col-3 col-lg-2 col-md-3 col-sm-4 p-3"
            >
                <div
                    class="nibnut-aspect-ratio-box nibnut-ratio-square"
                >
                    <div>
                        <img
                            v-if="!!token_at_index(index)"
                            :src="token_at_index(index).url"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="columns visual-password-token-containers">
            <div
                v-for="index in 3"
                :key="index"
                class="column col-3 col-lg-2 col-md-3 col-sm-4 p-3"
            >
                <div
                    :class="{ 'active': current_index === index }"
                    class="nibnut-aspect-ratio-box nibnut-ratio-square clickable"
                    @click="current_index = index"
                >
                    <div>
                        <img
                            v-if="token_at_index(index)"
                            :src="token_at_index(index).url"
                        />
                        <div
                            v-else
                            class="visual-password-token-empty-container"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!disabled" class="columns visual-password-tokens mt-4">
            <div
                v-for="choice in choices"
                :key="choice.id"
                class="column col-2 clickable"
                @click="set_token(choice)"
            >
                <div class="nibnut-aspect-ratio-box nibnut-ratio-square">
                    <div>
                        <img :src="choice.url" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "VisualPasswordInput",
    created () {
        this.$store.dispatch(
            "FETCH_RECORDS",
            {
                entity: "attachment",
                query: {
                    filter_on: "type",
                    filter: "password",
                    random: this.inputMode,
                    student_id: this.studentId,
                    per_page: this.inputMode ? 5 : 0,
                    page: 1,
                    fields: ["fieldset::login"]
                }
            }
        ).then(response => {
            this.current_records_ids = response.record_ids
        }).catch(error => {
            this.$error(error.message)
        })
    },
    methods: {
        token_at_index (index) {
            const tokens = this.tokens
            return tokens[index - 1] ? this.choices.find(choice => choice.token === tokens[index - 1]) : null
        },
        set_token (data) {
            const tokens = this.tokens
            tokens[this.current_index - 1] = data.token
            this.current_index++
            if(this.current_index > 3) this.current_index = 1
            this.$emit("input", tokens.join("-"))
        }
        /*
        dropped (index, data) {
            this.drag_on = 0
            const tokens = this.tokens
            tokens[index - 1] = data.token
            this.$emit("input", tokens.join("-"))
        }
        */
    },
    computed: {
        ...mapGetters(["entity_records"]),
        tokens () {
            const tokens = (this.value || "").split("-")
            return (new Array(3)).fill(null).map((noop, index) => {
                return tokens[index] || null
            })
        },
        choices () {
            return this.entity_records(this.entity, this.current_records_ids)
        }
    },
    props: {
        value: {
            type: String,
            default: ""
        },
        inputMode: {
            type: Boolean,
            default: true
        },
        studentId: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "attachment",
            current_records_ids: [],
            current_index: 1
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.visual-password-input {
    img {
        max-width: 100%;
        max-height: 100%;
    }

    .visual-password-token-containers,
    .visual-password-tokens {
        justify-content: center;
    }
    .visual-password-token-containers {
        .visual-password-token-empty-container {
            width: 100%;
            height: 100%;
            background-color: $bg-color;
            border-radius: 0.5rem;
            border-top: 2px solid $border-color-dark;
            border-left: 2px solid $border-color;
            border-bottom: 2px solid $border-color-light;
            border-right: 2px solid $border-color-light;
            transition: border 0.2s ease-in-out;
        }
        img {
            border-radius: 0.5rem;
            border: 2px dashed $brand-pink-dark;
        }

        .active {
            img,
            .visual-password-token-empty-container {
                border: 2px solid $brand-pink-dark;
            }
        }

        &.read-only {
            justify-content: flex-start;

            img {
                border: 0;
            }
        }
    }
    .visual-password-tokens {
        & > .column {
            padding: $unit-3;
            border: 1px dashed $border-color-light;
            @media(max-width:$size-sm) {
                padding: $unit-3;
            }

            & > div {
                cursor: grab;
            }
        }
    }
}
</style>
