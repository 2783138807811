<template>
    <div v-if="!!url" class="qr-code-image text-center">
        <img :src="url" />
        <div
            v-if="!!label"
            class="text-gray text-small"
        >
            {{ label }}
        </div>
    </div>
</template>

<script>
import QRCode from "qrcode"

export default {
    name: "QrCodeImage",
    mounted () {
        this.reset()
    },
    watch: {
        value: "reset"
    },
    methods: {
        reset () {
            this.url = ""
            if(this.value) {
                QRCode.toDataURL(this.value, {
                    scale: 1,
                    width: 60,
                    height: 60,
                    color: { dark: "#ffffffff", light: "#ffffff00" }
                }).then(url => {
                    this.url = url
                }).catch(error => {
                    this.$error(error)
                })
            }
        }
    },
    props: {
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            url: ""
        }
    }
}
</script>
