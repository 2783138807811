<template>
    <div class="sam-video-player">
        <loader v-if="loading" size="sm" />
        <div v-if="!!error" class="text-error text-center">{{ error }}</div>
    </div>
</template>

<script>
import Player from "@vimeo/player"
import Loader from "@/custom/components/Loader"

let player = null

export default {
    name: "VideoPlayer",
    components: {
        Loader
    },
    mounted () {
        this.load()
    },
    watch: {
        "settings.src": "load"
    },
    methods: {
        load () {
            this.error = ""
            if(!!this.settings && !!this.settings.src && !player) {
                setTimeout(() => {
                    const { src, ...options } = this.settings
                    player = new Player(this.$el, { ...options, responsive: true, url: src })
                    player.on("loaded", () => {
                        this.loading = false
                    })
                    player.on("error", ({ message }) => {
                        this.error = message
                        this.loading = false
                    })
                    player.on("play", () => {
                        this.$emit("play")
                    })
                    player.on("ended", () => {
                        this.$emit("ended")
                    })
                }, 2000)
            }
        }
    },
    props: {
        settings: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: true,
            error: ""
        }
    }
}
</script>

<style lang="scss">
.sam-video-player {
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    iframe {
        max-width: 100%;
        height: auto;
    }
}
</style>
