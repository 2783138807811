<template>
    <div class="award-badges">
        <div
            v-for="badge in badges"
            :key="badge.id"
        >
            <award-badge
                :badge="badge"
                :awarded-at="badge_awarded_at(badge)"
                :size="size"
            />
        </div>
    </div>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import AwardBadge from "./AwardBadge"

export default {
    name: "AwardBadges",
    mixins: [misc_utilities],
    components: {
        AwardBadge
    },
    methods: {
        badge_awarded_at (badge) {
            const award = this.awards.find(award => award.badge_id === badge.id)
            return award ? this.$moment(award.awarded_at) : ""
        }
    },
    computed: {
        badges () {
            return this.constants("badges")
        }
    },
    props: {
        awards: {
            type: Array,
            default () {
                return []
            }
        },
        size: {
            type: String,
            validator: prop => !!prop && (["sm", "md"].indexOf(prop) >= 0),
            default: "md"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.award-badges {
    display: flex;
    justify-content: center;
    margin: -$layout-spacing-sm;

    & > div {
        margin: $layout-spacing;
    }
}
</style>
