<template>
    <page
        v-bind="page_props"
        v-on="$listeners"
        @statused="statused"
        :class="{ 'multi-boxes': multiBoxes }"
        class="custom-page"
    >
        <template v-slot:title>
            <page-back
                v-if="!!$route && (!$route.meta || !$route.meta.student_route)"
                :is-root="isRoot"
                :fallback="backNavigationFallback"
                :title="title"
            />
            <slot name="title">
                <h1 v-if="!!$route && (!$route.meta || !$route.meta.student_route)">{{ title }}</h1>
            </slot>
        </template>
        <template v-slot:default>
            <div class="page-content-box">
                <div class="page-body">
                    <slot></slot>
                </div>
                <div class="page-footer print-hide">
                    <links-list
                        v-if="!!$route && (!$route.meta || !$route.meta.student_route)"
                        :links="footer_links"
                    />
                    <links-list :links="legalese_links" />
                    <links-list
                        v-if="!!$route && (!$route.meta || !$route.meta.student_route)"
                        :links="social_links" :borderless="true"
                    />
                </div>
            </div>
        </template>
    </page>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import Page from "@/nibnut/components/Page"
import PageBack from "./PageBack"
import LinksList from "@/custom/components/LinksList"

export default {
    name: "CustomPage",
    mixins: [misc_utilities, addl_profile_utilities],
    components: {
        Page,
        PageBack,
        LinksList
    },
    methods: {
        statused (status) {
            if((status === 200) && this.is_student && (!this.$route.meta || !this.$route.meta.student_route)) this.$router.replace({ name: "learn.home" })
            this.$emit("statused", status)
        },
        links (links) {
            const urls = this.setting("urls")
            if(!urls) return []
            return links.filter(link => {
                link.url = urls[link.id]
                return !!link.url
            })
        }
    },
    computed: {
        page_props () {
            const { isRoot, backNavigationFallback, multiBoxes, ...page_props } = this.$props
            return page_props
        },
        footer_links () {
            return this.links([
                { id: "about_us", label: this.$root.translate("About Us") },
                { id: "blog", label: this.$root.translate("Blog") },
                { id: "subscribe", label: this.$root.translate("Subscribe") },
                { id: "faq", label: this.$root.translate("FAQ") },
                { id: "help", label: this.$root.translate("Help") },
                { id: "testimonials", label: this.$root.translate("Testimonials") },
                { id: "contact_us", label: this.$root.translate("Contact Us") }
            ])
        },
        legalese_links () {
            return this.links([
                { id: "terms", label: this.$root.translate("Terms & Conditions") },
                { id: "privacy", label: this.$root.translate("Privacy Policy") },
                { id: "copyright", label: this.$root.translate("Copyright Policy") }
            ])
        },
        social_links () {
            return this.links([
                { id: "facebook", label: this.$root.translate("Facebook"), glyph: "facebook-square", library: "lab" },
                { id: "instagram", label: this.$root.translate("Instagram"), glyph: "instagram", library: "lab" },
                { id: "pinterest", label: this.$root.translate("Pinterest"), glyph: "pinterest-square", library: "lab" },
                { id: "youtube", label: this.$root.translate("YouTube"), glyph: "youtube", library: "lab" }
            ])
        }
    },
    props: {
        ...Page.props,
        isRoot: {
            type: Boolean,
            default: false
        },
        backNavigationFallback: {
            type: Object, // { title, href: (string | object) }
            default: null
        },
        multiBoxes: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.custom-page {
    width: 100%;

    &, & > .page,
    & > .page > .page-content,
    & > .page > .page-content > .page-content-box {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
    .page-waiting {
        position: relative;
        flex: 1 0 auto;
        width: 100%;
        height: 100%;
    }

    .page-content {
        h1, h2, h3, h4, h5, h6 { color: $brand-blue-dark; }

        .page-content-section,
        & > .page-content-box {
            background-color: $body-bg;
            border: 0;
            border-radius: $unit-1;
            @include shadow-variant(0.015rem);
        }
        .card.page-content-section {
            & > .card-header, & > .card-body, & > .card-footer {
                padding: $unit-2 $unit-8;
            }
            & > .card-header:first-child, & > .card-body:first-child, & > .card-footer:first-child {
                padding-top: $unit-8;
            }
            & > .card-header:last-child, & > .card-body:last-child, & > .card-footer:last-child {
                padding-bottom: $unit-8;
            }
            margin-bottom: $unit-4;
        }

        & > .page-content-box {
            padding: $unit-8;

            & > div.page-body {
                flex: 1 0 auto;
            }
            & > div.page-footer {
                margin-top: $unit-4;
                text-align: center;

                .links-list {
                    & > li {
                        i.las, i.lab {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

    &.multi-boxes {
        .page-content {
            & > .page-content-box {
                background-color: transparent;
                border-radius: 0;
                padding: 0;
                box-shadow: none;

                & > div.page-footer {
                    margin-top: 0;
                    background-color: $body-bg;
                    border-radius: $unit-1;
                    padding: $unit-4;
                    @include shadow-variant(0.015rem);
                }
            }
        }
    }

    @media print {
        .page-content {
            & > .page-content-box {
                background-color: transparent;
                border-radius: 0;
                padding: 0;
                box-shadow: none;
            }
        }
    }
}
.nibnut.student-content {
    .custom-page {
        & .page {
            padding: 0;
        }
        .page-content {
            & > h2 {
                font-size: 26px;
                font-weight: normal;
                color: $primary-color;
            }
            & > .page-content-box {
                background-color: transparent;
                border-radius: 0;
                box-shadow: none;
                padding-left: 0;
                padding-right: 0;

                & > div.page-footer {
                    margin-top: $unit-12;
                    font-size: 0.8rem;
                    font-weight: normal;
                }
            }
        }
    }
}
</style>
