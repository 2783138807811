<template>
    <div
        :class="{ tooltip: (size === 'sm') }"
        class="award-badge-container"
        :data-tooltip="(size === 'sm') ? badge.description : false"
    >
        <div
            :class="{ [`award-badge-${size}`]: true, disabled: !awardedAt }"
            class="award-badge"
            :style="badge_style"
        >
            <div class="award-badge-circle">
                <open-icon :glyph="badge.glyph" />
            </div>
            <div class="award-badge-ribbon">
                <span v-if="size === 'sm'">{{ badge.name }}</span>
                <span v-else-if="(size !== 'sm') && !awardedAt">???</span>
                <span v-else>{{ badge.description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "AwardBadge",
    components: {
        OpenIcon
    },
    computed: {
        badge_style () {
            if(!this.badge || !this.awardedAt) return false
            return {
                background: `linear-gradient(to bottom right, ${this.badge.light_color} 0%, ${this.badge.dark_color} 100%)`,
                color: this.badge.glyph_color
            }
        }
    },
    props: {
        badge: {
            type: Object,
            required: true
        },
        awardedAt: {
            required: true
        },
        size: {
            type: String,
            validator: prop => !!prop && (["sm", "md"].indexOf(prop) >= 0),
            default: "md"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.award-badge-container {
    display: inline-block;
}
.award-badge {
    position: relative;
    margin: 0 2em;
    width: 4em;
    height: 6.2em;
    border-radius: 0.6em;
    display: inline-block;
    top: 0;
    transition: all 0.2s ease;

    &:before,
    &:after {
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        background: inherit;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    &:before {
        transform: rotate(60deg);
    }
    &:after {
        transform: rotate(-60deg);
    }
    &:hover {
        top: -4px;
    }
    .award-badge-circle {
        width: 60px;
        height: 60px;
        position: absolute;
        background: #fff;
        z-index: 10;
        border-radius: 50%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;

        i {
            font-size: 2em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -55%);
        }
    }
    .award-badge-ribbon {
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 4px;
        padding: 5px;
        width: 9rem;
        height: 2rem;
        z-index: 11;
        color: #fff;
        top: 70%;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 0.4em;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
        background: linear-gradient(to bottom right, #555 0%, #333 100%);
        cursor: default;
        text-align: center;
    }

    &.disabled {
        background: linear-gradient(to bottom right, lighten($gray-color, 21%) 0%, lighten($gray-color, 19%) 100%);
        color: $gray-color-dark;

        .award-badge-ribbon {
            background: linear-gradient(to bottom right, #ddd 0%, #eee 100%);
        }
    }
    &.award-badge-sm {
        margin: 0 1em;
        width: 2em;
        height: 3.1em;
        border-radius: 5px;
        display: inline-block;
        top: 0;
        transition: all 0.2s ease;

        &:hover {
            top: 0pc;
        }
        .award-badge-circle {
            width: 30px;
            height: 30px;

            i {
                font-size: 1em;
            }
        }
        .award-badge-ribbon {
            border-radius: 2px;
            padding: 2px;
            width: 9em;
            height: auto;
            top: auto;
            bottom: 0.9em;
        }
    }
}
</style>
