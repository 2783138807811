<template>
    <div
        v-if="loading"
        class="py-8"
    >
        <loader />
    </div>
    <div
        v-else
        class="columns glossary-terms"
    >
        <glossary-term
            v-for="term in filtered_rows"
            :key="term.id"
            :record="term"
            :editable="editable"
            :playing="playing === term.id"
            @play="play"
            @pause="pause"
        />
        <glossary-term
            v-if="editable"
            :record="shell"
            :editable="editable"
            @saved="refresh"
        />
    </div>
</template>

<script>
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

import Loader from "@/custom/components/Loader"
import GlossaryTerm from "./GlossaryTerm"

let audio

export default {
    name: "GlossaryTerms",
    mixins: [is_remote_data_table_source],
    components: {
        Loader,
        GlossaryTerm
    },
    watch: {
        glossaryId: "reset"
    },
    methods: {
        pre_load () {
            this.filtered_by_selection = false
            this.playing = 0
            audio = null
            this.set_state_values({
                page: 1,
                filter_on: "asset_id",
                filter: this.glossaryId
            })

            if(this.editable && (!this.shell || (this.shell.asset_id !== this.glossaryId))) {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: this.entity, reset: true }
                ).then(record => {
                    this.shell = record
                    this.shell.asset_id = this.glossaryId
                })
            }
        },
        init_player () {
            if(!audio) {
                audio = new Audio()
                audio.onerror = () => {
                    console.error("Error loading audio", audio.error)
                }
                audio.addEventListener("canplay", () => {
                    if(this.playing) {
                        try {
                            audio.play().catch(error => {
                                console.log(error)
                                // if(error.name === "NotAllowedError") this.authorized = false
                                this.pause()
                            })
                        } catch (error) {
                            console.log(error)
                            // if(error.name === "NotAllowedError") this.authorized = false
                            this.pause()
                        }
                    }
                })
                audio.addEventListener("ended", () => {
                    this.playing = 0
                })
            }
        },
        play (term) {
            if(this.playing) this.pause()

            this.init_player()
            audio.src = term.sound_url
            audio.load()
            this.playing = term.id
        },
        pause () {
            if(this.playing) {
                audio.pause()
                this.playing = 0
            }
        }
    },
    computed: {
        state_identifier () {
            return "glossary-terms"
        },
        can_refresh () {
            return !!this.profile_id && !!this.glossaryId
        }
    },
    props: {
        glossaryId: {
            type: Number,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "glossary_term",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            shell: {},
            playing: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.glossary-terms {
    justify-content: center;
    align-items: center;
}
</style>
