<template>
    <ul
        v-if="!!links.length"
        :class="{ borderless }"
        class="links-list"
    >
        <li
            v-for="link in links"
            :key="link.id"
        >
            <base-link
                :href="translate_url(link.url)"
                :title="link.label"
            >
                <open-icon v-if="!!link.glyph" :glyph="link.glyph" :library="link.library" />
                <span v-else>{{ link.label }}</span>
            </base-link>
        </li>
    </ul>
</template>

<script>
import string_utilities from "@/custom/mixins/StringUtilities"

import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "LinksList",
    mixins: [string_utilities],
    components: {
        BaseLink,
        OpenIcon
    },
    props: {
        links: {
            type: Array,
            default () {
                return []
            }
        },
        borderless: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.links-list {
    list-style: none;
    margin: $unit-2 0;

    & > li {
        display: inline-block;
        padding: 0 $unit-2;
        border-right: 1px solid $border-color-light;

        &:last-child {
            border-right: 0;
        }
    }

    &.borderless {
        & > li { border-right: 0; }
    }
}
</style>
