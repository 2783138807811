<template>
    <div
        :class="{ 'btn-group-block': block }"
        class="btn-group language-picker"
    >
        <default-button
            v-for="language in languages"
            :key="language.code"
            size="sm"
            :active="current_language === language.code"
            @click.prevent="translate_to(language.code)"
        >
            {{ language.abbrev }}
        </default-button>
    </div>
</template>

<script type="text/javascript">
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "LanguagePicker",
    components: {
        DefaultButton
    },
    methods: {
        translate_to (language) {
            this.$store.dispatch("SET_LANGUAGE", { language }).then(() => {
                this.$i18n.set(language)
            })
        }
    },
    computed: {
        current_language () {
            if(!this.profile || !this.profile.language) return this.$i18n.locale() || "en"
            return this.profile.language || "en"
        },
        languages () {
            return [
                { code: "en", name: "English", abbrev: "En" },
                { code: "fr", name: "Français", abbrev: "Fr" }
            ]
        },
        current_language_abbrev () {
            const current_language_code = this.current_language
            const language = this.languages.find(language => language.code === current_language_code)
            return language ? language.abbrev : "En"
        }
    },
    props: {
        block: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.language-picker {
}
</style>
