<template>
    <div class="group-access-code">
        <h6>
            {{ translate("Group Access Code") }}
        </h6>
        <div class="group-access-code-container">
            <div>{{ group.access_code }}</div>
            <default-button
                color="primary"
                :title="translate('Send code')"
                @click.prevent.stop
                v-clipboard:copy="group.access_code"
                v-clipboard:success="clipboard_copied"
            >
                <open-icon glyph="clipboard" />
            </default-button>
        </div>

        <modal-dialog
            id="group-access-emailer"
            :show.sync="emailing"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Send Group Access Code") }}</span>
            </template>

            <form-token-input
                id="emails"
                name="emails"
                :value="emails"
                :tokenValidator="validate_email"
                :required="true"
                @input="add_email"
                @delete="remove_email"
            >
                <template v-slot:label>{{ translate("Send Code To") }}:</template>
                <template v-slot:hint>{{ translate("Separate multiple email addresses with spaces or commas") }}</template>
            </form-token-input>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="sending"
                        class="mx-2"
                        @click.prevent="close"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="sending"
                        :disabled="sending || !emails.length"
                        class="mx-2"
                        @click.prevent="send"
                    >
                        {{ translate("Send") }}
                        <open-icon glyph="paper-plane" class="ml-2" />
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import FormTokenInput from "@/nibnut/components/Inputs/FormTokenInput"

export default {
    name: "GroupAccessCode",
    mixins: [handles_errors],
    components: {
        ModalDialog,
        DefaultButton,
        OpenIcon,
        FormTokenInput
    },
    methods: {
        close () {
            this.emailing = false
            this.emails = []
        },
        validate_email (email) {
            return !!email && !!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        },
        add_email (email) {
            email = email.toLowerCase()
            const index = this.emails.indexOf(email)
            if(index < 0) this.emails.push(email)
        },
        remove_email (email) {
            const index = this.emails.indexOf(email.toLowerCase())
            if(index >= 0) this.emails.splice(index, 1)
        },
        send () {
            this.sending = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "group",
                    id: this.group.id,
                    action: "send-code",
                    method: "put",
                    data: {
                        emails: this.emails
                    }
                }
            ).then(record => {
                this.$success(this.translate("Email(s) sent!"))
                this.close()
            }).catch(this.receive_error).then(() => {
                this.sending = false
            })
        },
        clipboard_copied () {
            this.$success(this.translate("Access Code Copied!"))
        }
    },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            emails: [],
            emailing: false,
            sending: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.group-access-code {
    padding: $unit-4;
    background-color: $bg-color;

    & > .group-access-code-container {
        display: flex;
        justify-content: center;

        & > div {
            flex: 1 1 auto;
            font-size: 1rem;
        }
        & > button {
            flex: 0 0 auto;
        }
    }
}
</style>
