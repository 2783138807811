<template>
    <div class="columns">
        <div class="column col-12">
            <form-dropdown
                :id="`${id}-birth_month`"
                name="birth_month"
                v-model="record.birth_month"
                :options="months"
                :required="required"
                :editable="editable"
                :saving="saving"
                :error="error"
                @input="change_month"
            >
                <template v-slot:label>{{ $root.translate("Birth Month") }} 🎂</template>
            </form-dropdown>
        </div>
        <!--
        <div class="column col-6">
            <form-input
                :id="`${id}-birth_day`"
                name="birth_day"
                type="number"
                v-model="record.birth_day"
                :min="1"
                :max="max_day"
                :required="required"
                :editable="editable"
                :saving="saving"
                @input="save"
            >
                <template v-slot:label>{{ $root.translate("Birth Day") }} 🎂</template>
            </form-input>
        </div>
        //-->
    </div>
</template>

<script>
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
// import FormInput from "@/nibnut/components/Inputs/FormInput"

export default {
    name: "BirthMonthDayInput",
    components: {
        FormDropdown
        // FormInput
    },
    methods: {
        change_month (value, field) {
            this.save(value, field)

            /*
            const max_day = this.max_day
            const day = parseInt(this.record.birth_day) || 1
            if(day > max_day) this.save(max_day, "birth_day")
            */
        },
        save (value, field) {
            this.$emit("input", value, field)
        }
    },
    computed: {
        months () {
            return [
                { id: 1, name: this.$root.translate("January") },
                { id: 2, name: this.$root.translate("February") },
                { id: 3, name: this.$root.translate("March") },
                { id: 4, name: this.$root.translate("April") },
                { id: 5, name: this.$root.translate("May") },
                { id: 6, name: this.$root.translate("June") },
                { id: 7, name: this.$root.translate("July") },
                { id: 8, name: this.$root.translate("August") },
                { id: 9, name: this.$root.translate("September") },
                { id: 10, name: this.$root.translate("October") },
                { id: 11, name: this.$root.translate("November") },
                { id: 12, name: this.$root.translate("December") }
            ]
        },
        max_day () {
            const month_date = this.$moment(`2020-${this.record.birth_month}-01`) // use a leap year so Feb. 29th exists...
            return month_date.endOf("month").date()
        }
    },
    props: {
        id: {
            validator: prop => !!prop
        },
        record: {
            type: Object,
            required: true
        },
        required: {
            type: Boolean,
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
