<template>
    <div class="video-input-container">
        <form-group
            v-bind="form_group_props"
        >
            <template v-slot:empty-value>
                <video-player
                    :settings="player_settings"
                />
            </template>
            <template v-slot:label><slot name="label"></slot></template>
            <template v-slot:hint>
                <slot name="hint">
                    <span v-if="!!value && !!value.src">{{ value.src }}</span>
                </slot>
            </template>

            <div
                v-if="!value || !value.src"
                class="empty"
            >
                <div class="empty-icon">
                    <open-icon glyph="video" size="2x" />
                </div>
                <div class="empty-action">
                    <default-button
                        color="primary"
                        @click.prevent="edit"
                    >
                        {{ $root.translate("Add...") }}
                    </default-button>
                </div>
            </div>
            <div
                v-else
                class="video-input"
                @click.prevent="edit"
            >
                <video-player
                    :settings="player_settings"
                />
                <div class="overlay">
                    <default-button
                        color="primary"
                        class="s-circle"
                        @click.prevent="edit"
                    >
                        <open-icon glyph="plus" />
                    </default-button>
                </div>
            </div>
        </form-group>

        <modal-dialog
            id="video-input-dialog"
            :show.sync="inputing"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("Vimeo Video") }}</span>
            </template>

            <form-textbox
                id="snippet"
                name="snippet"
                v-model="snippet"
                :required="false"
            >
                <template v-slot:hint>{{ $root.translate("Paste here the iframe html snippet provided by Vimeo") }}</template>
            </form-textbox>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="saving"
                        class="mr-2"
                        @click.prevent="inputing = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="saving"
                        :disabled="saving"
                        color="primary"
                        class="ml-2"
                        @click.prevent="save"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import VideoPlayer from "../VideoPlayer"

export default {
    name: "VideoInput",
    mixins: [is_nibnut_component],
    components: {
        ModalDialog,
        FormGroup,
        FormTextbox,
        DefaultButton,
        OpenIcon,
        VideoPlayer
    },
    methods: {
        edit () {
            this.snippet = ""
            this.inputing = true
        },
        save () {
            if(this.snippet) {
                const parser = new DOMParser()
                const document = parser.parseFromString(this.snippet, "text/html")
                if(document) {
                    const iframes = document.getElementsByTagName("iframe")
                    if(!!iframes && !!iframes.length) {
                        const iframe = iframes[0]
                        const settings = {}
                        iframe.getAttributeNames().forEach(name => {
                            settings[name] = iframe.getAttribute(name)
                        })
                        this.$emit("input", settings, this.name)
                        this.inputing = false
                    }
                }
            }
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        player_settings () {
            if(!this.value) return {}
            return {
                ...this.value,
                byline: false,
                controls: false,
                dnt: true,
                keyboard: false,
                muted: true,
                portrait: false,
                title: false,
                height: 209,
                width: 370
            }
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            validator: prop => (prop === null) || (typeof prop === "object"),
            default () {
                return null
            }
        },
        required: {
            type: Boolean,
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            inputing: false,
            snippet: ""
        }
    }
}
</script>

<style lang="scss">
.video-input-container {
    display: inline-block;

    .video-input {
        position: relative;
        width: 370px;
        height: 209px;
        cursor: pointer;

        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            background-color: rgba(white, 0.9);

            .btn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            .overlay {
                display: block;
            }
        }
    }
}
</style>
