<template>
    <div
        :data-tooltip="tooltip"
        :class="{ active: is_active && !is_stuck, stuck: is_stuck }"
        class="activity-progress-container tooltip tooltip-left"
    >
        <figure
            class="pie-chart"
            :style="style"
        >
        </figure>
    </div>
</template>

<script>
export default {
    name: "ActivityProgress",
    computed: {
        stats () {
            const total_answers = this.assignment.computed_good_answers + this.assignment.computed_bad_answers
            const good_answers = total_answers ? Math.round((this.assignment.computed_good_answers / total_answers) * 100) : 0
            return {
                total_answers,
                good_answers,
                bad_answers: total_answers ? (100 - good_answers) : 0
            }
        },
        tooltip () {
            const lines = [
                this.assignment.asset_name
            ]
            const stats = this.stats
            if(!this.assignment.computed_last_seen_at) lines.push(this.$root.translate("Not started"))
            else {
                if(this.assignment.stuck) lines.push(this.$root.translate("STUDENT MAY BE STUCK"))
                else if(this.assignment.computed_in_progress) lines.push(this.$root.translate("IN PROGRESS"))
                else if(this.assignment.computed_last_seen_at) lines.push(this.nibnut_filter("nibnut.date", [this.assignment.computed_last_seen_at, "ll"]))

                lines.push(this.$root.translate("Success: {percent}%", { percent: this.nibnut_filter("nibnut.number", [stats.good_answers, "0,0"]) }))
                lines.push(this.$root.translate("Current Level: {level}", { level: this.assignment.current_level }))
            }

            return lines.join("\n")
        },
        style () {
            const slices = []
            const stats = this.stats
            if(stats.total_answers) {
                slices.push("#32b643 0")
                slices.push(`#32b643 ${stats.good_answers}%`)
                if(stats.bad_answers > 0) {
                    slices.push("#e85600 0")
                    slices.push("#e85600 100%")
                }
            } else {
                slices.push("#66758c 0")
                slices.push("#66758c 100%")
            }

            return {
                background: `radial-gradient(circle closest-side, transparent 100%, white 0), conic-gradient(${slices.join(",")})`
            }
        },
        is_active () {
            return !!this.assignment && this.assignment.computed_in_progress
        },
        is_stuck () {
            return !!this.assignment && this.assignment.stuck
        }
    },
    props: {
        assignment: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.activity-progress-container {
    position: relative;
    width: 30px;
    height: 30px;

    .pie-chart {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }

    &.active {
        .pie-chart {
            box-shadow: 0 0 0 0 rgba($success-color, 1);
            animation: activity-active-pulse 2s infinite;
        }
    }
    &.stuck {
        .pie-chart {
            box-shadow: 0 0 0 0 rgba($warning-color, 1);
            animation: activity-stuck-pulse 2s infinite;
        }
    }
}

@keyframes activity-active-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba($success-color, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba($success-color, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba($success-color, 0);
    }
}
@keyframes activity-stuck-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba($warning-color, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba($warning-color, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba($warning-color, 0);
    }
}
</style>
