<template>
    <div
        v-if="!!state.found"
        class="action-logs"
    >
        <default-button
            flavor="link"
            size="sm"
            :title="$root.translate('Action Logs')"
            :data-badge="state.unread_count ? state.unread_count : false"
            :class="{ badge: !!state.unread_count }"
            @click.prevent="toggle"
        >
            <open-icon glyph="clipboard-list" />
        </default-button>

        <modal-dialog
            id="action-logs-reader"
            :show.sync="viewing"
        >
            <template v-slot:title>
                <div class="h5 text-center">
                    {{ $root.translate("Action Logs") }}
                </div>
            </template>

            <data-table
                id="action-logs-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :searchable="false"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="false"
                :clickable="false"
                :show-head="false"
                @page="goto_page"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row.asset_name }}</h6>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === 'created_at'"
                    >
                        {{ row.created_at | nibnut.date("YYYY-MM-DD HH:mm") }}
                    </div>
                    <div
                        v-else-if="field === 'description'"
                    >
                        {{ row.description }}
                        <div
                            v-if="row.action === constants('actions', 'ACTION_CHANGE_STUDENT_ACTIVITY_LEVEL').id"
                            class="text-small text-gray"
                        >
                            {{ row.actor_name }}
                        </div>
                        <span
                            v-else
                        >
                            : {{ row.actor_name }}
                        </span>
                    </div>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>
        </modal-dialog>
    </div>
</template>

<script>
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ActionLogs",
    mixins: [is_remote_data_table_source],
    components: {
        ModalDialog,
        DefaultButton,
        OpenIcon
    },
    watch: {
        groupId: "reset"
    },
    methods: {
        pre_load () {
            this.set_state_value("group_id", this.groupId)
        },
        post_load (response) {
            // Anything to do after the current page's data has been loaded?
            if(this.page_autoscroll) this.page_autoscroll()
            this.set_state_value("unread_count", response._meta.unread_count || 0)
        },
        toggle () {
            if(this.viewing) this.viewing = false
            else {
                const group_user = this.group_user
                if(!!group_user.id && !!this.state.unread_count) {
                    this.$store.dispatch("RECORD_SAVE", {
                        entity: "group_user",
                        id: group_user.id,
                        data: {
                            last_read_at: this.$moment().format("YYYY-MM-DD HH:mm:ss")
                        }
                    }).then(() => {
                        this.set_state_value("unread_count", 0)
                    }).catch(error => {
                        this.$error(error.message)
                    })
                }
                this.viewing = true
            }
        }
    },
    computed: {
        state_identifier () {
            return `action-logs-group-${this.groupId}`
        },
        fields () {
            return ["fieldset::log-dialog", "ns::group_user;fieldset::log-dialog"]
        },
        group_user () {
            return this.entity_records("group_user").find(group_user => (group_user.group_id === this.groupId) && (group_user.user_id === this.profile_id)) || {}
        }
    },
    props: {
        groupId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "action_log",
            relation_ids: ["group_user"],
            columns: {
                created_at: { label: this.translate("Date"), sort: "desc", type: "amount" },
                description: { label: this.translate("Action"), sort: null, type: "alpha" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "created_at",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                unread_count: 0,
                group_id: 0
            },
            viewing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.action-logs {
    & > .btn {
        opacity: 1;
    }

    &.active {
        & > .btn {
            animation: activity-active-pulse 3s infinite;
        }
    }
}

@keyframes activity-active-pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
