<template>
    <div class="students-list">
        <div
            v-if="!profile_group_users.length && profile_is_subscribed"
            class="empty"
        >
            <div class="empty-icon">
                <open-icon glyph="users" size="5x" />
            </div>
            <p class="empty-title h5">{{ $root.translate("First things first...") }}</p>
            <p class="empty-subtitle">{{ $root.translate("Before you can add students to your account, you need to create at least one group.") }}</p>
            <div class="empty-action">
                <default-button
                    color="primary"
                    @click.prevent="group_new"
                >
                    {{ $root.translate("Create my first group...") }}
                </default-button>
            </div>
        </div>
        <data-table
            v-else
            id="students-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :empty-title="empty_title"
            :empty-add-button-title="empty_add_button_title"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="is_at_least_administrator || !!profile_editable_groups.length"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create()"
            @click="record_edit($event.id)"
        >
            <template
                v-slot:controls
            >
                <div v-if="!group && (profile_group_users.length > 1)">
                    <form-dropdown
                        id="group_id"
                        name="group_id"
                        :value="state.group_id"
                        :options="groups"
                        :empty-label="$root.translate('All Groups')"
                        :required="false"
                        @input="filter_by('group_id', $event)"
                    />
                </div>
                <div>
                    <form-dropdown
                        id="grade"
                        name="grade"
                        :value="state.grade"
                        :options="available_grades"
                        :empty-value="-1"
                        :empty-label="$root.translate('All Grades')"
                        :required="false"
                        @input="filter_by('grade', $event)"
                    />
                </div>
            </template>
            <template v-slot:buttons>
                <base-link
                    :href="{ name: 'group.access-cards', params: { id: !!group ? group.id : state.group_id } }"
                    target="_blank"
                    :title="$root.translate('Print All Access Cards')"
                    class="btn btn-link"
                >
                    <open-icon glyph="id-card" :title="$root.translate('Print All Access Cards')" />
                </base-link>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <img
                        v-if="!!row.image_url"
                        :src="row.image_url"
                        class="mr-2"
                    />
                    {{ row.name || user_for_row(row).name }}
                    <default-button
                        v-if="row.logged_in && (row.role === constants('roles', 'ROLE_STUDENT').id)"
                        flavor="link"
                        :title="translate('Logout')"
                        size="sm"
                        class="logout-button"
                        @click.prevent.stop="confirm_student_logout(row)"
                    >
                        <open-icon glyph="unlock-alt" />
                    </default-button>
                </h6>
                <div class="text-gray text-small">
                    {{ grade_by_id(user_for_row(row).grade).name }}
                </div>
                <div>
                    {{ group_for_row(row).name }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'user_id'"
                >
                    <img
                        v-if="!!row.image_url"
                        :src="row.image_url"
                        class="mr-2"
                    />
                    {{ row.name || user_for_row(row).name }}
                    <span class="text-gray text-small ml-2">
                        {{ grade_by_id(user_for_row(row).grade).name }}
                    </span>
                    <default-button
                        v-if="row.logged_in && (row.role === constants('roles', 'ROLE_STUDENT').id)"
                        flavor="link"
                        :title="translate('Logout')"
                        size="sm"
                        class="logout-button"
                        @click.prevent.stop="confirm_student_logout(row)"
                    >
                        <open-icon glyph="unlock-alt" />
                    </default-button>
                </div>
                <span
                    v-else-if="field === 'group_id'"
                >
                    {{ group_for_row(row).name }}
                </span>
                <span
                    v-else
                >
                    {{ row[field] }}
                </span>
            </template>
        </data-table>

        <new-group-dialog
            :show.sync="creating_group"
            :record="new_group"
        />
        <modal-dialog
            id="confirm-force-logout"
            :show.sync="confirming"
        >
            <p>{{ translate("To insure good statistics, please make sure there is not another student logged in this account by mistake.") }}</p>
            <form-input
                id="confirmed_name"
                name="confirmed_name"
                v-model="confirmed_name"
                :live="true"
                :required="false"
            >
                <template v-slot:label>{{ translate("To confirm the logout, please enter the student's name \"{name}\":", { name: active_student.username }) }}</template>
            </form-input>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="confirming = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :disabled="!student_logout_confirmed"
                        color="error"
                        class="ml-2"
                        @click.prevent="student_logout"
                    >
                        {{ $root.translate("Logout") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import handles_grades from "@/custom/mixins/HandlesGrades"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"
import NewGroupDialog from "@/custom/dialogs/NewGroupDialog"

export default {
    name: "StudentsList",
    mixins: [is_remote_data_table_source, handles_grades],
    components: {
        ModalDialog,
        FormInput,
        FormDropdown,
        DefaultButton,
        BaseLink,
        OpenIcon,
        NewGroupDialog
    },
    watch: {
        "group.id": "refresh"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                role: this.constants("group_user_types", "ROLE_STUDENT").id,
                group_id: this.group ? this.group.id : 0
            })
        },
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            if(this.state.filter_on && this.state.filter) {
                return rows.filter((row) => {
                    if((this.state.grade < 0) && (row.group_grade !== this.state.grade)) return false
                    if(!!this.state.group_id && (row.group_id !== this.state.group_id)) return false
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        user_for_row (row) {
            return this.entity_record("user", row.user_id) || {}
        },
        group_for_row (row) {
            return this.entity_record("group", row.group_id) || {}
        },
        record_edit (id = 0) {
            this.$router.push({ name: "student.edit", params: { id }, query: { group_id: this.group ? this.group.id : 0 } })
        },
        record_create () {
            this.record_edit()
        },
        group_new () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                {
                    entity: "group",
                    reset: true
                }
            ).then(record => {
                this.new_group = { ...record, user_id: this.profile_id }
                this.creating_group = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        confirm_student_logout (student) {
            this.active_student = student
            this.confirmed_name = ""
            this.confirming = true
        },
        student_logout () {
            if(this.student_logout_confirmed) {
                this.$store.dispatch("STUDENT_LOGOUT", { student_id: this.active_student.id }).catch(this.receive_error).then(() => {
                    this.confirming = false
                })
            }
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "students-list"
        },
        fields () {
            return [
                "fieldset::list",
                "ns::user;fieldset::group_user",
                "ns::group;fieldset::group_user"
            ]
        },
        visible_columns () {
            if(!this.group && (this.profile_group_users.length > 1)) return this.columns
            const columns = {
                user_id: this.columns.user_id,
                _buttons: this.columns._buttons
            }
            return columns
        },
        empty_title () {
            if(!this.profile_is_subscribed) return this.$root.translate("You do not have access to any students right now")
            return this.$root.translate("You have no students defined in your account... yet!")
        },
        empty_add_button_title () {
            if(this.profile_is_subscribed) return this.$root.translate("Add a new student...")
            return ""
        },
        groups () {
            const group_ids = this.profile_group_users.map(group_user => group_user.group_id)
            return orderBy(this.entity_records("group").filter(group => group_ids.indexOf(group.id) >= 0), "name", "asc")
        },
        student_logout_confirmed () {
            return !!this.active_student && !!this.active_student.username && !!this.confirmed_name && (this.active_student.username.toLowerCase() === this.confirmed_name.toLowerCase())
        }
    },
    props: {
        group: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            entity: "group_user",
            relation_ids: ["user", "group"],
            columns: {
                user_id: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                group_id: { label: this.translate("Group"), sort: null, type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                grade: -1,
                group_id: 0
            },

            creating_group: false,
            new_group: {},

            confirming: false,
            active_student: {},
            confirmed_name: null
        }
    }
}
</script>

<style lang="scss">
.students-list {
    table.table {
        img {
            max-height: 1em;
        }
    }
}
</style>
