<template>
    <i
        :title="title||false"
        aria-hidden="true"
        :class="{ [`flaticon-${glyph}`]: true }"
        class="fi"
    ></i>
</template>

<script type="text/javascript">
export default {
    name: "FlatIcon",
    props: {
        glyph: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ""
        }
    }
}
</script>
