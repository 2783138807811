<template>
    <div class="sam-access-card">
        <div
            v-if="!!user"
            class="card-header"
        >
            <app-logo />
            <div class="card-title h5 py-2 text-center">
               Compte de l'&eacute;l&egrave;ve<br />
               Student Account
            </div>
        </div>
        <div
            v-if="!!user"
            class="card-body"
        >
            <div class="columns mt-6">
                <div class="column col-6 text-center">
                    <img :src="user.image_url" />
                </div>
                <div class="column col-6">
                    <div>
                        <h6 class="access-card-group-name">
                            {{ user.group_name }}
                        </h6>
                        <h6 class="access-card-group-grade text-gray">
                            {{ grade_by_id(user.group_grade).name }}
                        </h6>
                        <form-input
                            id="username"
                            name="username"
                            v-model="user.username"
                            :required="false"
                            :editable="false"
                        />
                        <h6 v-if="(user.group_grade >= 0) && (user.group_grade >= setting('min_grade_for_image_password'))" class="access-card-password text-gray">
                            {{ translate("Password", {}, null, "fr") }}&nbsp;&bull;&nbsp;{{ translate("Password", {}, null, "en") }}
                        </h6>
                        <form-input
                            v-if="(user.group_grade >= 0) && (user.group_grade >= setting('min_grade_for_text_password'))"
                            id="password"
                            name="password"
                            v-model="user.password"
                            :required="false"
                            :editable="false"
                        />
                        <form-visual-password-input
                            v-else-if="(user.group_grade >= 0) && (user.group_grade >= setting('min_grade_for_image_password'))"
                            id="password"
                            name="password"
                            v-model="user.password"
                            :student-id="user.id"
                            :required="false"
                            :editable="false"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="card-body"
        >
            <img src="@/assets/img/logo.png" class="img-responsive app-logo" />
        </div>
    </div>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import handles_grades from "@/custom/mixins/HandlesGrades"

import AppLogo from "@/nibnut/components/AppLogo"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormVisualPasswordInput from "@/custom/components/Inputs/FormVisualPasswordInput"

export default {
    name: "AccessCard",
    mixins: [misc_utilities, handles_grades],
    components: {
        AppLogo,
        FormInput,
        FormVisualPasswordInput
    },
    props: {
        user: {
            validator: prop => !prop || (typeof prop === "object"),
            default: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.sam-access-card {
    position: relative;
    height: 350px;
    border: 2px solid $primary-color-light;
    border-radius: 0.25em;
    break-inside: avoid-page;
    overflow: hidden;
    background-color: $body-bg;
    font-weight: 700;

    & > .card-header {
        position: relative;
        background-color: $primary-color;
        color: $light-color;
        padding: $layout-spacing-lg;

        .app-logo {
            position: absolute;
            top: 0;
            left: 0;
            max-height: 6em;
        }
    }
    & > .card-body {
        padding: 0 $layout-spacing-lg $layout-spacing-lg $layout-spacing-lg;
        color: $primary-color;
        font-size: 26px;

        & > .columns {
            & > .column {
                position: relative;

                & > img, & > div  {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                }
                & > img  {
                    max-height: 9rem;
                    max-width: 90%;
                }
            }
        }

        & > .app-logo {
            position: absolute;
            max-height: 90%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
