<template>
    <div
        :class="{ empty: !url, 'popover': editable, 'popover-right': editable, 'active': picking, 'has-error': !!error }"
        class="form-group avatar-input"
        @click="picking = !picking"
    >
        <div
            class="nibnut-aspect-ratio-box nibnut-ratio-square"
        >
            <div>
                <img
                    v-if="!!url"
                    :src="url"
                />
                <open-icon
                    v-else
                    size="5x"
                    glyph="user-alt"
                    class="text-gray"
                />
            </div>
        </div>
        <div
            v-if="editable"
            class="popover-container"
        >
            <div class="card">
                <div class="card-body">
                    <form-dropdown
                        id="filter"
                        name="filter"
                        :value="filter"
                        :options="categories"
                        :required="true"
                        @input="filter_by_category"
                    />
                    <div
                        class="columns"
                    >
                        <div
                            v-for="avatar in filtered_avatars"
                            :key="avatar.id"
                            class="column col-3 col-sm-6"
                        >
                            <default-button
                                :block="true"
                                @click.prevent.stop="$emit('input', avatar.id, name)"
                            >
                                <img :src="avatar.url" :alt="avatar.name" />
                            </default-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!!error"
            class="form-input-hint text-center"
        >
            {{ error }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "AvatarInput",
    mixins: [misc_utilities],
    components: {
        FormDropdown,
        DefaultButton,
        OpenIcon
    },
    watch: {
        url: "reset_ui"
    },
    created () {
        this.$store.dispatch(
            "FETCH_RECORDS",
            {
                entity: "tag",
                query: {
                    taxonomy: this.constants("taxonomies", "TAXONOMY_AVATAR_CATEGORY").id
                }
            }
        ).then(() => {
            this.filter = this.categories.length ? this.categories[0].id : ""
        })
        this.$store.dispatch(
            "FETCH_RECORDS",
            {
                entity: "attachment"
            }
        )
    },
    methods: {
        reset_ui () {
            this.picking = false
        },
        filter_by_category (value) {
            this.filter = value
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        url () {
            const avatar = this.entity_record("attachment", this.value) || {}
            return avatar.url || ""
        },
        categories () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_AVATAR_CATEGORY").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), "name", "asc")
        },
        avatars () {
            const type = "avatar"
            return this.entity_records("attachment").filter(attachment => {
                return (attachment.type === type) && ((this.grade < 0) || (attachment.grades.indexOf(this.grade) >= 0))
            })
        },
        filtered_avatars () {
            return orderBy(this.avatars.filter(attachment => attachment.categories.indexOf(this.filter) >= 0), "name", "asc")
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // attachment_id
            default: 0
        },
        grade: {
            type: Number,
            default: -1
        },
        error: {
            type: String,
            default: ""
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            filter: "",
            picking: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.avatar-input {
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .btn {
        border: 0;
    }

    .nibnut-aspect-ratio-box {
        img, .las {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.popover {
        display: block;

        .popover-container {
            max-height: 50vh;

            .divider:first-child {
                &[data-content] {
                    margin-top: $unit-2;
                }
            }

            .columns {
                justify-content: center;
            }
        }
    }
    &.empty {
        border: $control-padding-x dashed $bg-color-dark;
        padding: 0;
    }
}
</style>
