<template>
    <figure class="avatar">
        <img :src="student.image_url" :alt="student.username">
        <i
            v-if="show_status"
            :class="{ online }"
            class="avatar-presence"
        />
    </figure>
</template>

<script>
export default {
    name: "Avatar",
    computed: {
        show_status () {
            return !!this.status && (typeof this.student.logged_in !== "undefined")
        },
        online () {
            return !!this.student && !!this.student.logged_in
        }
    },
    props: {
        student: {
            type: Object,
            required: true
        }
    }
}
</script>
