<template>
    <div>
        <div class="text-right mb-4">
            <base-link
                :href="{ name: 'troubleshoot', params: { group_id, user_id } }"
                :title="$root.translate('Troubleshoot')"
                class="btn btn-sm mr-2"
            >
                <open-icon glyph="first-aid" />
                {{ $root.translate('Troubleshoot') }}
            </base-link>
            <div class="btn-group">
                <default-button
                    :active="scope === 7"
                    size="sm"
                    @click.prevent="set_scope(7)"
                >
                    {{ $root.translate('This week') }}
                </default-button>
                <default-button
                    :active="scope === 30"
                    size="sm"
                    @click.prevent="set_scope(30)"
                >
                    {{ $root.translate('This month') }}
                </default-button>
                <default-button
                    :active="scope === 366"
                    size="sm"
                    @click.prevent="set_scope(366)"
                >
                    {{ $root.translate('This year') }}
                </default-button>
                <base-link
                    :href="{ name: 'stats', params: { group_id, user_id } }"
                    :title="$root.translate('View more statistics...')"
                    class="btn btn-sm"
                >
                    {{ $root.translate('More') }}
                    <open-icon glyph="arrow-right" />
                </base-link>
            </div>
        </div>

        <div v-if="!!entityId && !!report_data" class="columns graphs">
            <div class="column col-5 col-md-4 col-sm-12">
                <apexchart
                    v-if="!!report_data['game-time']"
                    :height="160"
                    type="area"
                    :options="chart_options('game-time')"
                    :series="chart_data('game-time')"
                />
            </div>
            <div class="column col-2 col-md-4 col-sm-12">
                <apexchart
                    v-if="!!report_data['time-by-topic']"
                    :height="160"
                    type="pie"
                    :options="chart_options('time-by-topic')"
                    :series="chart_data('time-by-topic')"
                />
            </div>
            <div class="column col-5 col-md-4 col-sm-12">
                <apexchart
                    v-if="!!report_data['success-rate']"
                    :height="160"
                    type="area"
                    :options="chart_options('success-rate')"
                    :series="chart_data('success-rate')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "SummaryGraphs",
    mixins: [addl_profile_utilities],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon,
        Apexchart: VueApexCharts
    },
    mounted () {
        this.load()
    },
    watch: {
        $route: "load",
        profile_id: "load",
        entityId: "load"
    },
    methods: {
        load () {
            if(!!this.profile_id && !!this.entityId) {
                this.$store.dispatch(
                    "RECORD_ACTION",
                    {
                        entity: this.entity,
                        id: this.entityId,
                        action: "stats",
                        passthru: true,
                        data: {
                            scope: this.scope
                        }
                    }
                ).then(response => {
                    this.report_data = response
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        set_scope (days) {
            if(this.scope !== days) {
                this.scope = days
                this.load()
            }
        },
        chart_options (chart_id) {
            if(chart_id === "game-time") {
                return {
                    chart: {
                        id: chart_id,
                        height: 160,
                        fontFamily: "QuickSand",
                        type: "area",
                        toolbar: {
                            show: false
                        }
                    },
                    title: {
                        text: this.$root.translate("Avg. Game Time")
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        opacity: 0.3
                    },
                    colors: ["#f3932c"],
                    tooltip: {
                        y: {
                            formatter: (value) => {
                                return `${this.$numeral(value * 60 / 100).format("0,0")} min`
                            }
                        }
                    },
                    xaxis: {
                        categories: this.report_data["game-time"] ? Object.keys(this.report_data["game-time"]).map(date => {
                            return this.$moment(date).format("MM/DD")
                        }) : []
                    },
                    yaxis: {
                        title: {
                            text: this.$root.translate("minutes")
                        },
                        forceNiceScale: true,
                        labels: {
                            formatter: (value, index) => {
                                return this.$numeral(value * 60 / 100).format("0,0")
                            }
                        }
                    }
                }
            }

            if(chart_id === "time-by-topic") {
                return {
                    chart: {
                        id: chart_id,
                        height: 160,
                        fontFamily: "QuickSand",
                        type: "pie"
                    },
                    dataLabels: {
                        enabled: false
                    },
                    title: {
                        text: this.$root.translate("Practice by Subject"),
                        align: "center"
                    },
                    legend: { show: false },
                    tooltip: {
                        y: {
                            formatter: (value) => {
                                return `${this.$numeral(value).format("0,0")}%`
                            }
                        }
                    },
                    labels: this.report_data["time-by-topic"] ? this.report_data["time-by-topic"].map(topic => topic.label) : [],
                    colors: this.report_data["time-by-topic"] ? this.report_data["time-by-topic"].map(topic => topic.color) : []
                }
            }

            if(chart_id === "success-rate") {
                return {
                    chart: {
                        id: chart_id,
                        height: 160,
                        fontFamily: "QuickSand",
                        type: "area",
                        toolbar: {
                            show: false
                        }
                    },
                    title: {
                        text: this.$root.translate("Progress Rate"),
                        align: "right"
                    },
                    legend: {
                        show: false
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        opacity: 0.3
                    },
                    colors: this.report_data["success-rate"] ? this.report_data["success-rate"].map(topic => topic.color) : [],
                    tooltip: {
                        y: {
                            formatter: (value) => {
                                return `${this.$numeral(value).format("0,0")}%`
                            }
                        }
                    },
                    xaxis: {
                        categories: this.report_data["success-rate"] ? Object.keys(this.report_data["success-rate"][0].value).map(date => {
                            return this.$moment(date).format("MM/DD")
                        }) : []
                    },
                    yaxis: {
                        forceNiceScale: true,
                        decimalsInFloats: true,
                        labels: {
                            formatter: (value) => {
                                return `${this.$numeral(value).format("0,0")}%`
                            }
                        }
                    }
                }
            }
        },
        chart_data (chart_id) {
            if(chart_id === "game-time") {
                return [
                    {
                        name: this.$root.translate("Avg. Game Time"),
                        data: this.report_data["game-time"] ? Object.values(this.report_data["game-time"]) : []
                    }
                ]
            }

            if(chart_id === "time-by-topic") {
                return this.report_data["time-by-topic"] ? this.report_data["time-by-topic"].map(topic => topic.value) : []
            }

            if(chart_id === "success-rate") {
                const data = []
                this.report_data["success-rate"].forEach(dataset => {
                    data.push({
                        name: dataset.label,
                        data: Object.values(dataset.value)
                    })
                })
                return data
            }
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        group_id () {
            if(this.entity === "user") return 0
            if(this.entity === "group_user") return this.entity_record("group_user", this.entityId).group_id
            return this.entityId
        },
        user_id () {
            if(this.entity === "group") return null
            if(this.entity === "group_user") return this.entity_record("group_user", this.entityId).user_id
            return this.entityId
        }
    },
    props: {
        entity: {
            type: String,
            required: true
        },
        entityId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            scope: 7,
            report_data: {}
        }
    }
}
</script>
