<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <visual-password-input
                    :value="value"
                    :input-mode="inputMode"
                    :student-id="studentId"
                    :disabled="true"
                />
            </slot>
        </template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint>
            <span v-if="!inputMode && is_incomplete">{{ $root.translate("NOTE: the password will not be saved until you fill out all 3 boxes") }}</span>
            <span v-else-if="!!inputMode">{{ $root.translate("Click your password images to place them in the boxes") }}</span>
        </template>

        <visual-password-input
            :value="value"
            :input-mode="inputMode"
            :student-id="studentId"
            @input="maybe_emit"
        />
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import VisualPasswordInput from "./VisualPasswordInput"

export default {
    name: "FormVisualPasswordInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        VisualPasswordInput
    },
    methods: {
        incomplete (value) {
            const tokens = (value || "").split("-").filter(token => !!token)
            return tokens.length < 3
        },
        maybe_emit (value) {
            if(!this.incomplete(value)) this.$emit("input", value, this.name)
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        is_incomplete () {
            return this.incomplete(this.value)
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        },
        live: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        },
        inputMode: {
            type: Boolean,
            default: true
        },
        studentId: {
            type: Number,
            default: 0
        }
    }
}
</script>
