<template>
    <div class="form-group">
        <label
            class="form-label"
        >
            <slot name="label">{{ $root.translate("Payment Info") }}</slot>
        </label>
        <card
            :id="id"
            class="stripe-card"
            :class="{ cc_ready }"
            :stripe="stripe_key"
            :options="stripe_options"
            @change="card_changed"
        />
    </div>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import { Card } from "vue-stripe-elements-plus"

export default {
    name: "CreditCardInput",
    mixins: [misc_utilities],
    components: {
        Card
    },
    methods: {
        card_changed (event) {
            this.cc_ready = event.complete
        }
    },
    computed: {
        stripe_key () {
            return this.setting("stripe_key")
        },
        stripe_options () {
            return {
                elements: { locale: this.$store.state.i18n.locale || "en" }
            }
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        owedAmount: {
            type: Number,
            default: 0
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            cc_ready: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.stripe-card {
    border: $border-width solid $border-color-dark;
    border-radius: $border-radius;
    color: $body-font-color;
    display: block;
    font-size: $font-size;
    height: $control-size;
    line-height: $line-height;
    max-width: 100%;
    outline: none;
    padding: $control-padding-y $control-padding-x;
    position: relative;
    width: 100%;
}
</style>
