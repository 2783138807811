<template>
    <div
        class="nibnut-accordion"
    >
        <div
            v-for="tab in tabs"
            :key="tab.id"
            :class="{ 'active': (currentTabId === tab.id) }"
            class="nibnut-accordion-panel"
        >
            <div
                class="nibnut-accordion-panel-header"
                @click.prevent="$emit('click', tab.id)"
            >
                <img
                    v-if="!!tab.img"
                    :src="tab.img"
                    class="mr-2"
                />
                <flat-icon
                    v-else-if="!!tab.glyph && (tab.library === 'flat')"
                    :glyph="tab.glyph"
                    class="mr-2"
                />
                <open-icon
                    v-else-if="!!tab.glyph"
                    :glyph="tab.glyph"
                    :size="tab.glyph_size || ''"
                    class="mr-2"
                />
                <span v-if="!!tab.label">{{ tab.label }}</span>
            </div>
            <div class="nibnut-accordion-panel-body">
                <transition
                    name="expand"
                    mode="out-in"
                    @enter="enter"
                    @after-enter="after_enter"
                    @leave="leave"
                >
                    <slot :tab_id="tab.id" />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import FlatIcon from "@/custom/components/FlatIcon"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "Accordion",
    components: {
        OpenIcon,
        FlatIcon
    },
    methods: {
        enter (element) {
            const width = getComputedStyle(element).width
            element.style.width = width
            element.style.position = "absolute"
            element.style.visibility = "hidden"
            element.style.height = "auto"

            const height = getComputedStyle(element).height
            element.style.width = null
            element.style.position = null
            element.style.visibility = null
            element.style.height = 0

            // Force repaint to make sure the
            // animation is triggered correctly.
            /* eslint-disable no-unused-expressions */
            getComputedStyle(element).height

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
                element.style.height = height
            })
        },
        after_enter (element) {
            element.style.height = "auto"
        },
        leave (element) {
            const height = getComputedStyle(element).height

            element.style.height = height

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height

            requestAnimationFrame(() => {
                element.style.height = 0
            })
        }
    },
    props: {
        tabs: {
            type: Array,
            default () {
                return [] // { id: "", label: "", img: "", glyph: "", library: "" }
            }
        },
        currentTabId: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-accordion {
    z-index: 0;

    .nibnut-accordion-panel {
        z-index: 0;

        & > .nibnut-accordion-panel-header {
            display: flex;
            margin-top: -1px;
            padding: $unit-2 $unit-1;
            border-top: 1px solid $brand-green;
            border-bottom: 1px solid $brand-green;
            cursor: pointer;
            transition: all 0.3s ease-in;

            img {
                max-height: 1.7em;
                z-index: -1;
            }
            i.fi, i.las, i.lab {
                font-size: 1.7em;
                transform: rotate(0deg);
                transition: transform 0.3s ease-in;
                z-index: -1;
            }
            span {
                flex: 1 0 100%;
            }
        }
        & > .nibnut-accordion-panel-body {
            & > div {
                will-change: height;
                transform: translateZ(0);
                backface-visibility: hidden;
                perspective: 1000px;
                padding: $unit-4 $unit-1;
            }
        }

        &.active {
            & > .nibnut-accordion-panel-header {
                background-color: $brand-green;
                color: $light-color;

                img {
                    z-index: 0;
                }
                i.fi, i.las, i.lab {
                    transform: rotate(90deg);
                    z-index: 0;
                }
            }
        }
    }
}
</style>
