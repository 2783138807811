<template>
    <div class="groups-list">
        <data-table
            id="groups-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="can_add"
            entity-name="Group:::Groups"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create(entity)"
            @click="record_edit(entity, $event.id)"
        >
            <template
                v-slot:controls
            >
                <div
                    v-if="profile_group_users.length !== profile_editable_groups.length"
                >
                    <form-dropdown
                        id="owner_id"
                        name="owner_id"
                        :value="state.owner_id"
                        :options="owners"
                        :empty-label="$root.translate('All Owners')"
                        label-field="group_name"
                        :required="false"
                        @input="filter_by('owner_id', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <img
                        v-if="!!row.image_url"
                        :src="row.image_url"
                        class="mr-2"
                    />
                    {{ row.name }}
                </h6>
                <div class="my-4">
                    <span v-if="row.grade >= 0">{{ grade_by_id(row.grade).name }}</span>
                    <span v-else class="text-gray">{{ $root.translate("No grade assigned") }}</span>
                </div>
                <div class="mb-2">
                    {{ row.computed_number_of_members | nibnut.number("0,0") }} {{ $root.translate("Collaborators") }}
                </div>
                <div>
                    {{ row.computed_number_of_students | nibnut.number("0,0") }} {{ $root.translate("Students") }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'name'"
                >
                    <img
                        v-if="!!row.image_url"
                        :src="row.image_url"
                        class="mr-2"
                    />
                    {{ row.name }}
                </div>
                <span
                    v-else-if="field === 'grade'"
                >
                    <span v-if="row.grade >= 0">{{ grade_by_id(row.grade).name }}</span>
                    <span v-else class="text-gray">{{ $root.translate("No grade assigned") }}</span>
                </span>
                <span
                    v-else-if="field.match(/^computed_number_of/i)"
                >
                    {{ row[field] | nibnut.number("0,0") }}
                </span>
                <span
                    v-else
                >
                    {{ row[field] }}
                </span>
            </template>
        </data-table>
    </div>
</template>

<script>
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import crud_utilities from "@/nibnut/mixins/CrudUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import handles_grades from "@/custom/mixins/HandlesGrades"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"

export default {
    name: "GroupsList",
    mixins: [is_remote_data_table_source, crud_utilities, addl_profile_utilities, handles_grades],
    components: {
        FormDropdown
    },
    mounted () {
        this.reroot()
    },
    watch: {
        user: "reroot"
    },
    methods: {
        reroot () {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                user_id: this.user ? this.user.id : 0,
                _force_all: this.user ? !!this.user._global : false
            })
            this.refresh()
        },
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        }
    },
    computed: {
        state_identifier () {
            return "groups-list"
        },
        can_refresh () {
            return !!this.profile_id && !!this.state.user_id
        },
        fields () {
            return [
                "fieldset::list",
                "ns::group_user;fieldset::group-list"
            ]
        },
        can_add () {
            return !!this.user && !!this.user.id && (this.user.role !== this.constants("roles", "ROLE_STUDENT").id) && ((this.profile_is_subscribed && (this.user.id === this.profile_id)) || this.is_at_least_administrator)
        },
        owners () {
            const user = this.user || this.profile
            if(!user) return []
            const group_ids = this.group_users_by_user_id(user.id).map(group_user => group_user.group_id)
            return this.entity_records("group_user").filter(group_user => {
                return (group_ids.indexOf(group_user.group_id) >= 0)
            })
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "group",
            relation_ids: ["group_user"],
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                grade: { label: this.translate("Grade"), sort: null, type: "numeric" },
                computed_number_of_members: { label: this.translate("Collab."), sort: null, type: "numeric" },
                computed_number_of_students: { label: this.translate("Students"), sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                user_id: 0,
                owner_id: 0,
                _force_all: false
            }
        }
    }
}
</script>

<style lang="scss">
.groups-list {
    table.table {
        img {
            max-height: 1em;
        }
    }
}
</style>
