<template>
    <div
        class="tile-ticker"
        :style="{ height: `${this.itemWidth}px` }"
    >
        <div>
            <div
                ref="tiles"
                class="tiles"
                :style="{ left: `${tiles_left}px` }"
            >
                <slot></slot>
            </div>
        </div>
        <a
            href="#"
            class="ticker-navigation previous"
            @click.prevent="previous_page"
        >
            <open-icon glyph="arrow-left" size="lg" />
        </a>
        <a
            href="#"
            class="ticker-navigation next"
            @click.prevent="next_page"
        >
            <open-icon glyph="arrow-right" size="lg" />
        </a>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "Ticker",
    components: {
        OpenIcon
    },
    methods: {
        move_page_by (delta) {
            let left = this.$refs.tiles.offsetLeft + (this.itemWidth * delta)
            if(left > 0) left = 0
            else {
                const max = this.$refs.tiles.offsetWidth - this.$el.clientWidth + this.parentPadding
                if(Math.abs(left) > max) left = 0 - max
            }
            this.tiles_left = left
        },
        previous_page () {
            this.move_page_by(1)
        },
        next_page () {
            this.move_page_by(-1)
        }
    },
    props: {
        itemWidth: {
            type: Number,
            default: 330
        },
        parentPadding: {
            type: Number,
            default: 100
        }
    },
    data () {
        return {
            tiles_left: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.tile-ticker {
    position: relative;
    padding: 0 2.5rem;

    & > div {
        position: relative;
        display: block;
        overflow: hidden;
        height: 100%;
        margin: 0;
        padding: 0;

        & > .tiles {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
            //margin: 0 ($unit-2 * -1);
            white-space: nowrap;
            transition: left 0.3s ease-in-out;

            & > .tile {
                display: inline-block;
                height: 100%;
                min-height: 1px;
                margin: 0 $layout-spacing;

                & > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    & > a.ticker-navigation {
        position: absolute;
        display: block;
        top: 50%;
        width: 2rem;
        height: 2rem;
        line-height: 2.2rem;
        background-color: $light-color;
        color: $brand-blue;
        border-radius: 50%;
        transform: translate(0, -50%);
        @include shadow-variant(0.015rem);

        & > i.las {
            position: relative;
            font-size: 1.2em;
            vertical-align: -0.06em;
        }

        &.previous {
            left: 0;

            & > i.las { left: 3px; }
        }
        &.next {
            right: 0;

            & > i.las { right: -3px; }
        }
    }
}
</style>
