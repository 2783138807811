<template>
    <form-input
        id="coupon_code"
        name="coupon_code"
        v-model="coupon_code"
        :required="false"
        :live="false"
        :saving="fetching_coupon_code"
        :editable="editable"
        :error="has_error('coupon_code')"
        @blur="fetch_coupon"
    >
        <template v-slot:label>{{ $root.translate("Coupon Code") }}</template>
    </form-input>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"

import FormInput from "@/nibnut/components/Inputs/FormInput"

export default {
    name: "CouponCodeInput",
    mixins: [handles_errors],
    components: {
        FormInput
    },
    watch: {
        coupon: "check_coupon_validity"
    },
    methods: {
        set_coupon (coupon) {
            this.$emit("input", coupon)
        },
        fetch_coupon () {
            if(this.coupon_code) {
                this.clear_all_errors()
                this.fetching_coupon_code = true
                this.$store.dispatch(
                    "REBATE",
                    { code: this.coupon_code }
                ).then(this.set_coupon).catch(error => {
                    this.receive_error(error)
                    this.set_coupon(null)
                }).then(() => {
                    this.fetching_coupon_code = false
                })
            } else this.set_coupon(null)
        },
        check_coupon_validity () {
            if(!!this.coupon && !this.coupon.is_valid) this.add_error("coupon_code", this.$root.translate("This coupon code is not valid."))
        }
    },
    props: {
        coupon: {
            type: Object,
            default: null
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            coupon_code: "",
            fetching_coupon_code: false
        }
    }
}
</script>
