<template>
    <div>
        <div class="activities-grid grid-picker">
            <div class="controls">
                <div v-if="types.length > 1">
                    <form-dropdown
                        id="type"
                        name="type"
                        v-model="state.type"
                        :options="types"
                        :required="false"
                        empty-value=""
                        :empty-label="translate('All types')"
                        @input="filter_by('type', $event)"
                    />
                </div>
                <div>
                    <form-dropdown
                        id="subject_tag_id"
                        name="subject_tag_id"
                        v-model="state.subject_tag_id"
                        :options="subjects"
                        :required="false"
                        :empty-label="translate('All subjects')"
                        @input="filter_by('subject_tag_id', $event)"
                    />
                </div>
                <div>
                    <form-dropdown
                        id="grade"
                        name="grade"
                        :value="state.grade"
                        :options="available_grades"
                        :empty-value="-1"
                        :empty-label="translate('Any grade')"
                        :required="false"
                        @input="filter_by('grade', $event)"
                    />
                </div>
                <div>
                    <form-input
                        id="search"
                        name="search"
                        v-model="state.search"
                        glyph="search"
                        glyph-position="right"
                        :required="false"
                        :live="true"
                        @input="search"
                    />
                </div>
            </div>

            <div
                v-if="state.found === 0"
                class="empty"
            >
                <div class="empty-subtitle">({{ translate("No asset found...") }})</div>
            </div>
            <div
                v-else
                class="columns semi-responsive"
            >
                <div
                    v-for="row in filtered_rows"
                    :key="row.id"
                    class="column game-tile-container"
                    @click="$emit('click', row)"
                >
                    <div>
                        <base-link
                            v-if="canPreview || canBulkAssign"
                            :href="asset_url(row)"
                            target="_blank"
                            @click="preview($event, row)"
                        >
                            <game-tile
                                :game="row"
                                :selected="selection === row.id"
                            />
                        </base-link>
                        <base-link
                            v-else
                            href="#"
                            @click.prevent="$emit('input', row.id)"
                        >
                            <game-tile
                                :game="row"
                                :selected="selection === row.id"
                            />
                        </base-link>
                        <div v-if="canPreview || canBulkAssign" class="text-center mt-2">
                            <base-link
                                v-if="canPreview"
                                :href="asset_url(row)"
                                target="_blank"
                                class="btn btn-link btn-sm mx-2"
                                @click="preview($event, row)"
                            >
                                {{ translate("Preview") }}
                            </base-link>
                            <default-button
                                v-if="canBulkAssign"
                                flavor="link"
                                size="sm"
                                class="mx-2"
                                @click.prevent="bulk_assign(row)"
                            >
                                {{ translate("Assign") }}
                            </default-button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!loading && (nb_pages > 1)">
                <ul v-if="nb_pages > 1" class="pagination">
                    <li
                        :class="{ 'd-invisible': !has_prev_page }"
                        class="page-item"
                    >
                        <base-link
                            href="#"
                            @click.prevent="goto_page(1)"
                        >
                            <open-icon
                                glyph="chevron-left"
                            />
                        </base-link>
                    </li>
                    <li
                        v-for="page in pages"
                        :key="page.number"
                        :class="{ 'active': page.number === state.page }"
                        class="page-item"
                    >
                        <base-link
                            v-if="page.delta"
                            href="#"
                            @click.prevent="goto_page(state.page + page.delta)"
                        >
                            ...
                        </base-link>
                        <base-link
                            v-else
                            href="#"
                            @click.prevent="goto_page(page.number)"
                        >
                            {{ page.number }}
                        </base-link>
                    </li>
                    <li
                        :class="{ 'd-invisible': !has_next_page }"
                        class="page-item"
                    >
                        <base-link
                            href="#"
                            @click.prevent="goto_page(nb_pages)"
                        >
                            <open-icon
                                glyph="chevron-right"
                            />
                        </base-link>
                    </li>
                </ul>
                <div
                    :class="{ 'mt-4': (nb_pages <= 1) }"
                    class="text-small text-gray text-center"
                >
                    <span v-if="state.found !== state.total">{{ found_caption }} / </span>{{ total_caption }}
                </div>
            </div>
        </div>

        <video-player-dialog
            :show.sync="watching"
            :name="watching_name"
            :settings="watching_settings"
        />
        <bulk-assign-activity-dialog
            :show.sync="bulk_assigning"
            :activity="action_record"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import handles_grades from "@/custom/mixins/HandlesGrades"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"
import GameTile from "@/custom/components/GameTile"
import VideoPlayerDialog from "@/custom/dialogs/VideoPlayerDialog"
import BulkAssignActivityDialog from "./BulkAssignActivityDialog"

export default {
    name: "ActivitiesGrid",
    mixins: [is_remote_data_table_source, misc_utilities, handles_grades],
    components: {
        FormDropdown,
        FormInput,
        DefaultButton,
        BaseLink,
        OpenIcon,
        GameTile,
        VideoPlayerDialog,
        BulkAssignActivityDialog
    },
    mounted () {
        this.reset_subject_tag_id()
    },
    watch: {
        subjectTagId: "reset_subject_tag_id",
        onlyTypes: "reset_subject_tag_id"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                student_id: this.student ? this.student.id : 0
            })
        },
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        },
        search (query) {
            this.$emit("search", query)
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            if(this.state.filter_on && this.state.filter) {
                rows = rows.filter((row) => {
                    if(row.id === this.selection) return true
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }

            rows = rows.filter(row => {
                if(row.id === this.selection) return true
                return !this.student || (this.student.asset_ids.indexOf(row.id) < 0)
            })

            if(this.state.type) {
                rows = rows.filter(row => {
                    if(row.id === this.selection) return true
                    return row.type === this.state.type
                })
            }
            if(this.state.subject_tag_id) {
                rows = rows.filter(row => {
                    if(row.id === this.selection) return true
                    return row.subject_tag_id === this.state.subject_tag_id
                })
            }

            return rows
        },
        asset_url (asset) {
            if(asset.type === this.constants("asset_types", "TYPE_VIDEO").id) return "#"
            else {
                let route_name = "learn.game"
                if(asset.type === this.constants("asset_types", "TYPE_GLOSSARY").id) route_name = "learn.glossary"
                const route = this.$router.resolve({ name: route_name, params: { id: asset.slug }, query: { grade: this.state.grade } })
                return route.href
            }
        },
        preview (event, asset) {
            if(asset.type === this.constants("asset_types", "TYPE_VIDEO").id) {
                event.preventDefault()
                this.watching_name = asset.name
                this.watching_settings = asset.settings
                this.watching = true
            }
        },
        bulk_assign (asset) {
            this.action_record = asset
            this.bulk_assigning = true
        },
        reset_subject_tag_id () {
            if(
                (!this.state.type && !!this.onlyTypes && !!this.onlyTypes.length) ||
                (!!this.onlyTypes && !!this.onlyTypes.length && (!this.state.type || (this.onlyTypes.indexOf(this.state.type) < 0)))
            ) this.set_state_value("type", this.onlyTypes[0])
            if(this.subjectTagId && (this.subjectTagId !== this.state.subject_tag_id)) this.filter_by("subject_tag_id", this.subjectTagId)
        }
    },
    computed: {
        state_identifier () {
            return this.stateIdentifier || this.$route.name
        },
        fields () {
            return ["fieldset::picker"]
        },
        raw_subjects () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_SUBJECT").id
            return this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy)
        },
        subjects () {
            return orderBy(this.raw_subjects, this.translated_field_name("name"), "asc")
        },
        types () {
            const audience_role = this.constants("roles", "ROLE_STUDENT").id
            const types = Object.values(this.constants("asset_types"))
            return types.filter(asset_type => {
                return (!asset_type.audience_role || (asset_type.audience_role === audience_role)) && (!this.onlyTypes || !this.onlyTypes.length || (this.onlyTypes.indexOf(asset_type.id) >= 0))
            })
        },
        pages () {
            const pages = []

            const visible_around = Math.ceil((this.page_range - 1) / 2)

            let from = this.state.page - visible_around
            if(from <= 0) from = 1

            let to = from + (this.page_range - 1)
            if(to > this.nb_pages) {
                from -= (to - this.nb_pages)
                if(from <= 0) from = 1
                to = this.nb_pages
            }

            if((from - 1) >= 1) pages.push({ number: null, delta: -1 })

            for(let loop = from; loop <= to; loop++) pages.push({ number: loop })

            if((this.nb_pages - to) >= 1) pages.push({ number: null, delta: 1 })

            return pages
        },
        has_prev_page () {
            return this.state.page > 1
        },
        has_next_page () {
            return this.state.page < this.nb_pages
        },
        found_caption () {
            return this.translate("{number} {singular_entity_name} found:::{number} {plural_entity_name} found", { number: this.state.found, singular_entity_name: this.translate(this.entity_name, {}, 1).toLowerCase(), plural_entity_name: this.translate(this.entity_name, {}, 2).toLowerCase() }, this.state.found)
        },
        total_caption () {
            return this.translate("{number} {singular_entity_name}:::{number} {plural_entity_name}", { number: this.state.total, singular_entity_name: this.translate(this.entity_name, {}, 1).toLowerCase(), plural_entity_name: this.translate(this.entity_name, {}, 2).toLowerCase() }, this.state.total)
        }
    },
    props: {
        stateIdentifier: {
            type: String,
            default: ""
        },
        student: {
            type: Object,
            default () {
                return null
            }
        },
        onlyTypes: {
            type: Array,
            default () {
                return []
            }
        },
        selection: {
            type: Number,
            default: 0
        },
        subjectTagId: {
            type: Number,
            default: 0
        },
        canPreview: {
            type: Boolean,
            default: false
        },
        canBulkAssign: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "asset",
            entity_name: "activité:::activités",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                role: 1,
                type: "",
                subject_tag_id: 0,
                grade: -1,
                student_id: 0
            },
            page_range: 3,
            watching: false,
            watching_name: "",
            watching_settings: {},
            action_record: {},
            bulk_assigning: false
        }
    }
}
</script>
